import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Option } from "constants/styled-components";
import { FaHistory, FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import { OSAGoodIcon, OSAOkayIcon, OSAPoorIcon } from "components/analytics-reports/reports/on-shelf-availability/osa_legend_icons";
import { H3 } from "components/feed/filter-rules/condition/Condition.styled";
import React from "react";
export var FILTER_RULES_FULL_WIDTH_BREAKPOINT = 640;
export var MATCH_OPTIONS = [
    {
        group: "Popular",
        options: [
            {
                value: "retailers",
                label: "Retailer",
                suggest: true,
            },
            {
                value: "brands",
                label: "Brand",
                suggest: true,
            },
            {
                value: "text",
                label: "Text",
                suggest: false,
                text: true,
            },
            {
                value: "categories",
                label: "Category",
                suggest: true,
            },
            {
                value: "tags",
                label: "Tag",
                suggest: false,
            },
            {
                value: "dates",
                label: "Date",
                suggest: false,
            },
        ],
    },
    {
        group: "Geography",
        options: [
            {
                value: "regions",
                label: "Region",
                suggest: false,
            },
            {
                value: "countries",
                label: "Country",
                suggest: true,
            },
            {
                value: "states",
                label: "State",
                suggest: true,
            },
            {
                value: "cities",
                label: "City",
                suggest: true,
            },
            {
                value: "demographics",
                label: "Demography",
                suggest: false,
            },
            {
                value: "postal_zip",
                label: "Post Code",
                suggest: false,
                text: true,
            },
            {
                value: "map",
                label: "Map",
                suggest: false,
                noSelector: true
            },
        ],
    },
    {
        group: "Other",
        options: [
            {
                value: "users",
                label: "User",
                suggest: false,
            },
            {
                value: "months",
                label: "Month",
                suggest: false,
            },
            {
                value: "locations",
                label: "Store Location",
                suggest: false,
            },
            {
                value: "channels",
                label: "Channel",
                suggest: true,
            },
            {
                value: "time_travel",
                label: "Time Travel",
                suggest: false,
            },
            {
                value: "collections",
                label: "Collection",
                suggest: true,
            },
            {
                value: "osa",
                label: "Stock Levels",
                suggest: false,
            }
        ],
    },
];
export var textMatchTypes = MATCH_OPTIONS
    .map(function (group) { return group.options; }).flat()
    .filter(function (option) { return "text" in option; })
    .map(function (option) { return option.value; });
var isSelectableMatchOption = function (option) {
    return (!("noSelector" in option && option.noSelector) &&
        !("text" in option && option.text));
};
export var selectableMatchTypes = MATCH_OPTIONS
    .map(function (group) { return group.options; }).flat()
    .filter(isSelectableMatchOption)
    .map(function (option) { return option.value; });
export var dateOptions = [
    {
        label: "Custom dates",
        options: [
            {
                value: "Custom",
                name: "Custom",
                id: "custom",
                noImg: true,
                type: "dates",
                label: (_jsxs(Option, { children: [_jsx(FaRegCalendarAlt, {}), "Custom"] })),
            },
        ],
    },
    {
        label: "Preset options",
        options: [
            {
                value: "Today",
                name: "Today",
                id: "today",
                noImg: true,
                type: "dates",
                label: _jsx(Option, { children: "Today" }),
            },
            {
                value: "Yesterday",
                name: "Yesterday",
                noImg: true,
                id: "yesterday",
                type: "dates",
                label: _jsx(Option, { children: "Yesterday" }),
            },
            {
                value: "Last 7 days",
                name: "Last 7 days",
                noImg: true,
                id: "l7",
                type: "dates",
                label: _jsx(Option, { children: "Last 7 days" }),
            },
            {
                value: "Last 30 days",
                name: "Last 30 days",
                noImg: true,
                id: "l30",
                type: "dates",
                label: _jsx(Option, { children: "Last 30 days" }),
            },
            {
                value: "Last 90 days",
                name: "Last 90 days",
                noImg: true,
                id: "l90",
                type: "dates",
                label: _jsx(Option, { children: "Last 90 days" }),
            },
            {
                value: "Last 365 days",
                name: "Last 365 days",
                noImg: true,
                id: "l365",
                type: "dates",
                label: _jsx(Option, { children: "Last 365 days" }),
            },
            {
                value: "Previous 30 days",
                name: "Previous 30 days",
                noImg: true,
                id: "p30",
                type: "dates",
                label: _jsx(Option, { children: "Previous 30 days" }),
            },
            {
                value: "Previous 90 days",
                name: "Previous 90 days",
                noImg: true,
                id: "p90",
                type: "dates",
                label: _jsx(Option, { children: "Previous 90 days" }),
            },
            {
                value: "Previous 365 days",
                name: "Previous 365 days",
                noImg: true,
                id: "p365",
                type: "dates",
                label: _jsx(Option, { children: "Previous 365 days" }),
            },
            {
                value: "Current month",
                name: "Current month",
                noImg: true,
                id: "currentMonth",
                type: "dates",
                label: _jsx(Option, { children: "Current month" }),
            },
            {
                value: "Last month",
                name: "Last month",
                noImg: true,
                id: "lastMonth",
                type: "dates",
                label: _jsx(Option, { children: "Last month" }),
            },
            {
                value: "Year to date",
                name: "Year to date",
                noImg: true,
                id: "ytd",
                type: "dates",
                label: _jsx(Option, { children: "Year to date" }),
            },
        ],
    },
];
export var singleSelectors = [
    "users",
    "dates",
    "time_travel",
    "question_id",
    "osa"
];
export var isNoneOption = {
    value: "is none",
    noImg: true,
    disabled: false,
    label: "is none",
    id: "is_none",
};
export var isNoneOptionSelectors = [
    "brands",
    "categories",
    "products"
];
export var isAllOptionSelectors = [
    "brands",
    "categories",
    "collections",
    "tags",
];
export var isAllOption = {
    value: "is all of",
    label: "is all of",
    noImg: true,
    disabled: false,
    id: "is_all",
};
export var isOptions = function (isSingle) { return [
    {
        value: isSingle ? "is" : "is any of",
        label: isSingle ? "is" : "is any of",
        noImg: true,
        disabled: false,
        id: "is",
    },
    {
        value: isSingle ? "is not" : "is none of",
        label: isSingle ? "is not" : "is none of",
        noImg: true,
        disabled: false,
        id: "is_not",
    },
]; };
export var isOptionsNumeric = [
    {
        value: "is",
        label: "are ≥",
        noImg: true,
        disabled: false,
        id: "is",
    },
    {
        value: "is not",
        label: "are ≤",
        noImg: true,
        disabled: false,
        id: "is_not",
    },
];
export var isOptionsText = [
    {
        id: "is",
        value: "includes",
        label: "includes",
        noImg: true,
    },
    {
        id: "is_not",
        value: "excludes",
        label: "excludes",
        noImg: true,
    },
];
export var timeTravelOptions = [
    {
        value: "enabled",
        label: (_jsxs(Option, { children: [_jsx(FaHistory, {}), "Available"] })),
        type: "time_travel",
        name: "Available",
        noImg: true,
        id: "enabled",
    },
    {
        value: "disabled",
        label: (_jsxs(Option, { children: [_jsx(FaRegClock, {}), "Not yet available"] })),
        type: "time_travel",
        name: "Not yet available",
        noImg: true,
        id: "disabled",
    },
];
export var osaOptions = [
    {
        value: "50",
        label: "50%",
        noImg: true,
        disabled: false,
        type: "osa",
        id: "50",
    },
    {
        value: "55",
        label: "55%",
        noImg: true,
        disabled: false,
        type: "osa",
        id: "55",
    },
    {
        value: "60",
        label: "60%",
        noImg: true,
        disabled: false,
        type: "osa",
        id: "60",
    },
    {
        value: "65",
        label: "65%",
        noImg: true,
        disabled: false,
        type: "osa",
        id: "65",
    },
    {
        value: "70",
        label: "70%",
        noImg: true,
        disabled: false,
        type: "osa",
        id: "70",
    },
    {
        value: "75",
        label: "75%",
        noImg: true,
        disabled: false,
        type: "osa",
        id: "75",
    },
    {
        value: "80",
        label: "80%",
        noImg: true,
        disabled: false,
        type: "osa",
        id: "80",
    },
    {
        value: "85",
        label: "85%",
        noImg: true,
        disabled: false,
        type: "osa",
        id: "85",
    },
    {
        value: "90",
        label: "90%",
        noImg: true,
        disabled: false,
        type: "osa",
        id: "90",
    },
    {
        value: "95",
        label: "95%",
        noImg: true,
        disabled: false,
        type: "osa",
        id: "95",
    },
];
export var presetOsaOptions = {
    good: (_jsxs(Option, { children: [_jsx(OSAGoodIcon, {}), _jsx(H3, { children: "Good (over 90% in stock)" })] })),
    okay: (_jsxs(Option, { children: [_jsx(OSAOkayIcon, {}), _jsx(H3, { children: "Okay (70% - 89% in stock)" })] })),
    poor: (_jsxs(Option, { children: [_jsx(OSAPoorIcon, {}), _jsx(H3, { children: "Poor ( <70% in stock )" })] })),
};
export var matchMinWidthMap = {
    osa: "14em",
    demographics: "14em",
    postal_zip: "14em",
    private_collections: "15em",
    collections: "13em",
    time_travel: "14em",
    locations: '14em',
};
