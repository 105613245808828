var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from "@reduxjs/toolkit";
import { fetchSuggestionsForLandingPage } from "@redux/api/endpoints/suggestions";
import { addLocalSuggestion } from "@redux/actions/suggestions";
import { initialSuggestions } from "shared/types/suggestions";
import { logout } from "@redux/actions/logout";
var feedSuggestionsLandingPageSlice = createSlice({
    name: "feedSuggestionsLandingPage",
    initialState: initialSuggestions,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(fetchSuggestionsForLandingPage.fulfilled, function (state, action) {
            state[action.meta.arg.routeParams.suggestionType] = action.payload;
        })
            .addCase(addLocalSuggestion, function (state, action) {
            var _a, _b;
            var _c = action.payload, suggestionType = _c.suggestionType, item = _c.item;
            var existingSuggestions = (_b = (_a = state[suggestionType]) === null || _a === void 0 ? void 0 : _a.filter(function (suggestion) { return suggestion.id !== item.id; })) !== null && _b !== void 0 ? _b : [];
            state[suggestionType] = __spreadArray([item], existingSuggestions, true).slice(0, 10);
        })
            .addCase("CHANGE_LANDING_PAGE_PROFILE_PIC", function (state, action) {
            var _a;
            var _b = action.data, landing_page_slug = _b.landing_page_slug, img = _b.img, source = _b.source;
            var updatedCollection = (_a = state.collections) === null || _a === void 0 ? void 0 : _a.find(function (collection) { return collection.id === landing_page_slug; });
            if (updatedCollection) {
                updatedCollection.source = source;
                updatedCollection.img = img;
            }
        })
            .addCase(logout, function () { return initialSuggestions; })
            .addCase("SWITCH_PRIVATE_SPACE", function () { return initialSuggestions; })
            .addCase("LANDING_PAGE_ABOUT_DATA_RESET", function () { return initialSuggestions; });
    }
});
export default feedSuggestionsLandingPageSlice.reducer;
