var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from "@reduxjs/toolkit";
import { initialSuggestions } from "shared/types/suggestions";
import { fetchSuggestionsForMainFeed } from "@redux/api/endpoints/suggestions";
import { addLocalSuggestion } from "@redux/actions/suggestions";
import { fetchInitialLoad } from "@redux/api/endpoints/search";
import { getTypedEntries } from "shared/types/utilityTypes";
import { logout } from "@redux/actions/logout";
var initialState = __assign(__assign({}, initialSuggestions), { fetchedFromServer: false });
var feedSuggestionsMainSlice = createSlice({
    name: "feedSuggestionsMain",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(fetchInitialLoad.fulfilled, function (state, action) {
            state.countries = action.payload.data.countries.filter(function (country) { return country.suggested; });
        })
            .addCase(fetchSuggestionsForMainFeed.fulfilled, function (state, action) {
            getTypedEntries(action.payload).forEach(function (_a) {
                var suggestionType = _a[0], suggestions = _a[1];
                state[suggestionType] = suggestions;
            });
            state.fetchedFromServer = new Date().toJSON();
        })
            .addCase(addLocalSuggestion, function (state, action) {
            var _a, _b;
            var _c = action.payload, suggestionType = _c.suggestionType, item = _c.item;
            var existingSuggestions = (_b = (_a = state[suggestionType]) === null || _a === void 0 ? void 0 : _a.filter(function (suggestion) { return suggestion.id !== item.id; })) !== null && _b !== void 0 ? _b : [];
            state[suggestionType] = __spreadArray([item], existingSuggestions, true).slice(0, 10);
        })
            .addCase("CHANGE_LANDING_PAGE_PROFILE_PIC", function (state, action) {
            var _a;
            var _b = action.data, landing_page_slug = _b.landing_page_slug, img = _b.img, source = _b.source;
            var updatedCollection = (_a = state.collections) === null || _a === void 0 ? void 0 : _a.find(function (collection) { return collection.id === landing_page_slug; });
            if (updatedCollection) {
                updatedCollection.source = source;
                updatedCollection.img = img;
            }
        })
            .addCase(logout, function () { return initialState; })
            .addCase("SWITCH_PRIVATE_SPACE", function () { return initialState; });
    }
});
export default feedSuggestionsMainSlice.reducer;
