var _a, _b;
import { selectUserData } from "@redux/selectors/user";
import { preloadPhoto } from "shared/helpers/image";
import { emptyArray } from "constants/variables";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { isTruthy } from "shared/helpers/typeGuards";
import { isAllOptionSelectors, isNoneOptionSelectors, textMatchTypes, } from "components/feed/filter-rules/FilterRules.config";
import { hideModal } from "@redux/slices/modalSlice";
import { logout } from "@redux/actions/logout";
import { prefetchedPhotosApplied } from "@redux/slices/feedSlice";
import { cloneDeep } from "lodash";
var initialState = {
    new: { filter: [] },
    existing: { filter: [] },
    query: { filter: [] },
    prefetchedData: null,
    last_updated: Date.now(),
    trigger: Date.now(),
    disabled: false,
    blur: false,
    showSettings: false,
};
/**
 * Type guard for narrowing the type of a ``Condition`` against an array of ``MatchType`` values.
 */
export var matchTypes = function (matchTypes) {
    return {
        matches: function (condition) {
            return condition.match !== null && matchTypes.includes(condition.match);
        }
    };
};
var conditionToQuery = function (condition) {
    var _a;
    return ({
        is: condition.is,
        match: condition.match,
        endDate: condition.endDate,
        startDate: condition.startDate,
        ids: ((_a = condition.item) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item.id; })) || emptyArray
    });
};
export var feedFilterToQueryFilter = function (filter) {
    return filter.map(function (rule) {
        return rule
            .filter(function (condition) { return condition.match !== null; })
            .map(conditionToQuery);
    });
};
var feedFilterRulesSlice = createSlice({
    name: "feedFilterRules",
    initialState: initialState,
    reducers: {
        feedFiltersCleared: function (state) {
            state.new = initialState.new;
        },
        feedFiltersReloadTriggered: function (state) {
            state.trigger = Date.now();
        },
        feedFiltersBlurred: function (state) {
            state.blur = true;
        },
        feedFiltersFocused: function (state) {
            state.blur = false;
        },
        feedFiltersDisabled: function (state) {
            state.disabled = true;
        },
        feedFiltersEnabled: function (state) {
            state.disabled = false;
        },
        feedFiltersSettingsToggled: function (state) {
            state.showSettings = !state.showSettings;
        },
        feedFiltersIdsChecked: function (state, action) {
            state.new.filterIds = action.payload.filterIds;
        },
        feedFiltersReset: function () {
            return initialState;
        },
        prefetchedPhotosCleared: function (state) {
            state.prefetchedData = null;
        },
        feedFiltersPresetApplied: function (state, action) {
            state.query.filter = feedFilterToQueryFilter(action.payload.filter);
            state.last_updated = Date.now();
            state.existing = action.payload;
            state.new = action.payload;
        },
        feedFiltersChangesApplied: function (state) {
            state.query.filter = feedFilterToQueryFilter(state.new.filter);
            state.existing = cloneDeep(state.new);
            state.last_updated = Date.now();
        },
        feedFiltersChangesCancelled: function (state) {
            state.new = cloneDeep(state.existing);
            state.last_updated = Date.now();
            state.prefetchedData = null;
            state.disabled = false;
            state.blur = false;
        },
        feedFiltersSortUpdated: function (state, action) {
            state.new.sort = action.payload.sort;
            state.existing.sort = action.payload.sort;
            state.query.sort = action.payload.sort;
        },
        feedFilterRuleAdded: function (state) {
            state.last_updated = Date.now();
            state.new.filter.push([
                {
                    match: null,
                    is: "is",
                    item: null,
                    id: Date.now(),
                }
            ]);
        },
        feedFilterRuleDuplicated: function (state, action) {
            var duplicatedRule = state.new.filter[action.payload.ruleIndex];
            if (duplicatedRule) {
                state.last_updated = Date.now();
                state.new.filter.push(cloneDeep(duplicatedRule));
            }
        },
        feedFilterRuleRemoved: function (state, action) {
            state.last_updated = Date.now();
            state.new.filter.splice(action.payload.ruleIndex, 1);
        },
        feedFilterGroupsDisabled: function (state) {
            if (state.new.filter.length > 1) {
                state.last_updated = Date.now();
                state.new.filter.splice(1);
            }
        },
        feedFilterConditionAdded: function (state, action) {
            var rule = state.new.filter[action.payload.ruleIndex];
            if (rule) {
                state.last_updated = Date.now();
                rule.push({
                    match: null,
                    is: "is",
                    item: null,
                    id: Date.now(),
                });
            }
        },
        feedFilterConditionRemoved: function (state, action) {
            var _a = action.payload, ruleIndex = _a.ruleIndex, conditionIndex = _a.conditionIndex;
            var rule = state.new.filter[ruleIndex];
            if (rule === null || rule === void 0 ? void 0 : rule[conditionIndex]) {
                var isRetailerCondition = rule[conditionIndex].match === "retailers";
                rule.splice(conditionIndex, 1);
                if (isRetailerCondition) {
                    state.new.filter[ruleIndex] = rule.filter(function (condition) {
                        return condition.match !== "locations";
                    });
                }
                if (!state.new.filter[ruleIndex].length) {
                    state.new.filter.splice(ruleIndex, 1);
                }
                state.last_updated = Date.now();
            }
        },
        feedFilterMapUpdated: function (state, action) {
            var _a;
            var _b = action.payload, ruleIndex = _b.ruleIndex, conditionIndex = _b.conditionIndex, item = _b.item, is = _b.is;
            var condition = (_a = state.new.filter[ruleIndex]) === null || _a === void 0 ? void 0 : _a[conditionIndex];
            if ((condition === null || condition === void 0 ? void 0 : condition.match) === "map") {
                state.last_updated = Date.now();
                condition.item = [item];
                condition.is = is;
            }
        },
        feedFilterDateRangeUpdated: function (state, action) {
            var _a, _b, _c;
            var _d = action.payload, ruleIndex = _d.ruleIndex, conditionIndex = _d.conditionIndex, startDate = _d.startDate, endDate = _d.endDate;
            var condition = (_a = state.new.filter[ruleIndex]) === null || _a === void 0 ? void 0 : _a[conditionIndex];
            if ((condition === null || condition === void 0 ? void 0 : condition.match) === "dates" && ((_c = (_b = condition.item) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id) === "custom") {
                if (startDate !== undefined) {
                    condition.startDate = startDate;
                }
                if (endDate !== undefined) {
                    condition.endDate = endDate;
                }
                state.last_updated = Date.now();
            }
        },
        feedFilterConditionLogicUpdated: function (state, action) {
            var _a = action.payload, ruleIndex = _a.ruleIndex, conditionIndex = _a.conditionIndex, match = _a.match, is = _a.is;
            var rule = state.new.filter[ruleIndex];
            var condition = rule === null || rule === void 0 ? void 0 : rule[conditionIndex];
            if ((condition === null || condition === void 0 ? void 0 : condition.match) === match) {
                condition.is = is;
                if (condition.match === "retailers" && is !== "is") {
                    state.new.filter[ruleIndex] = rule.filter(function (condition) {
                        return condition.match !== "locations";
                    });
                }
                state.last_updated = Date.now();
            }
        },
        feedFilterConditionMatchUpdated: function (state, action) {
            var _a = action.payload, ruleIndex = _a.ruleIndex, conditionIndex = _a.conditionIndex, match = _a.match;
            var rule = state.new.filter[ruleIndex];
            var condition = rule === null || rule === void 0 ? void 0 : rule[conditionIndex];
            if (condition) {
                var matchWasRetailer = condition.match === "retailers";
                condition.match = match;
                condition.item = null;
                if (matchWasRetailer) {
                    state.new.filter[ruleIndex] = rule.filter(function (condition) {
                        return condition.match !== "locations";
                    });
                }
                if (condition.is === "is_none" && !isNoneOptionSelectors.includes(condition.match) ||
                    condition.is === "is_all" && !isAllOptionSelectors.includes(condition.match) ||
                    condition.match === "dates") {
                    condition.is = "is";
                }
                state.last_updated = Date.now();
            }
        },
        feedFilterConditionItemUpdated: function (state, action) {
            var _a, _b;
            var _c = action.payload, ruleIndex = _c.ruleIndex, conditionIndex = _c.conditionIndex, match = _c.match, item = _c.item;
            var rule = state.new.filter[ruleIndex];
            var condition = rule === null || rule === void 0 ? void 0 : rule[conditionIndex];
            if ((condition === null || condition === void 0 ? void 0 : condition.match) === match) {
                if (condition.match === "retailers" && ((_a = condition.item) === null || _a === void 0 ? void 0 : _a.length) &&
                    (!item || item.length < condition.item.length)) {
                    var locationCondition = rule.find(function (condition) {
                        return condition.match === "locations";
                    });
                    if ((_b = locationCondition === null || locationCondition === void 0 ? void 0 : locationCondition.item) === null || _b === void 0 ? void 0 : _b.length) {
                        if (!item || !(item === null || item === void 0 ? void 0 : item.length)) {
                            locationCondition.item = null;
                        }
                        else {
                            var selectedRetailerIds_1 = item.map(function (retailer) { return retailer.id; });
                            var removedRetailer_1 = condition.item.find(function (retailer) {
                                return !selectedRetailerIds_1.includes(retailer.id);
                            });
                            if (removedRetailer_1) {
                                locationCondition.item = locationCondition.item.filter(function (location) {
                                    return !location.name.startsWith(removedRetailer_1.name);
                                });
                            }
                        }
                    }
                }
                condition.item = item;
                state.last_updated = Date.now();
            }
        }
    },
    extraReducers: function (builder) {
        builder
            .addCase(prefetchedPhotosApplied, function (state) {
            feedFilterRulesSlice.caseReducers.prefetchedPhotosCleared(state);
        })
            .addCase("FILTERS_CHECK_RESULTS_COUNT_PHOTOS_DATA", function (state, action) {
            state.prefetchedData = action.payload;
        })
            .addCase("HIDE_FULLSCREEN_MODAL", function (state) {
            state.new.filter.forEach(function (rule) {
                var emptyMapConditionIndex = rule.findIndex(function (condition) {
                    return condition.match === "map" && condition.item === null;
                });
                if (emptyMapConditionIndex !== -1) {
                    rule.splice(emptyMapConditionIndex, 1);
                }
            });
            state.new.filter = state.new.filter.filter(function (rule) {
                return rule.length > 0;
            });
            state.last_updated = Date.now();
        })
            .addCase(hideModal, function (state) {
            state.showSettings = false;
            state.blur = false;
        })
            .addCase(logout, function () { return initialState; });
    },
    selectors: {
        selectNewFeedFilters: function (sliceState) { return sliceState.new.filter; },
        selectExistingFeedFilters: function (sliceState) { return sliceState.existing.filter; },
        selectNewFeedFilterRuleCount: function (sliceState) { return sliceState.new.filter.length; },
        selectExistingFeedFilterRuleCount: function (sliceState) { return sliceState.existing.filter.length; }
    }
});
export default feedFilterRulesSlice.reducer;
export var feedFiltersCleared = (_a = feedFilterRulesSlice.actions, _a.feedFiltersCleared), feedFiltersReloadTriggered = _a.feedFiltersReloadTriggered, feedFiltersBlurred = _a.feedFiltersBlurred, feedFiltersFocused = _a.feedFiltersFocused, feedFiltersDisabled = _a.feedFiltersDisabled, feedFiltersEnabled = _a.feedFiltersEnabled, feedFiltersSettingsToggled = _a.feedFiltersSettingsToggled, feedFiltersIdsChecked = _a.feedFiltersIdsChecked, feedFiltersReset = _a.feedFiltersReset, prefetchedPhotosCleared = _a.prefetchedPhotosCleared, feedFiltersPresetApplied = _a.feedFiltersPresetApplied, feedFiltersChangesApplied = _a.feedFiltersChangesApplied, feedFiltersChangesCancelled = _a.feedFiltersChangesCancelled, feedFiltersSortUpdated = _a.feedFiltersSortUpdated, feedFilterRuleAdded = _a.feedFilterRuleAdded, feedFilterRuleDuplicated = _a.feedFilterRuleDuplicated, feedFilterRuleRemoved = _a.feedFilterRuleRemoved, feedFilterGroupsDisabled = _a.feedFilterGroupsDisabled, feedFilterConditionAdded = _a.feedFilterConditionAdded, feedFilterConditionRemoved = _a.feedFilterConditionRemoved, feedFilterMapUpdated = _a.feedFilterMapUpdated, feedFilterDateRangeUpdated = _a.feedFilterDateRangeUpdated, feedFilterConditionMatchUpdated = _a.feedFilterConditionMatchUpdated;
/**
 * Action creators for the condition logic/item update actions.
 *
 * These are needed because the auto‑generated action creators from createSlice lose the generic, distributed
 * type information and instead produces a single payload type with a union of all possible values
 */
function createFeedFilterConditionLogicUpdatedAction(payload) {
    return {
        type: feedFilterRulesSlice.actions.feedFilterConditionLogicUpdated.type,
        payload: payload
    };
}
function createFeedFilterConditionItemUpdatedAction(payload) {
    return {
        type: feedFilterRulesSlice.actions.feedFilterConditionItemUpdated.type,
        payload: payload
    };
}
export var feedFilterConditionLogicUpdated = createFeedFilterConditionLogicUpdatedAction;
export var feedFilterConditionItemUpdated = createFeedFilterConditionItemUpdatedAction;
export var selectNewFeedFilters = (_b = feedFilterRulesSlice.selectors, _b.selectNewFeedFilters), selectExistingFeedFilters = _b.selectExistingFeedFilters, selectNewFeedFilterRuleCount = _b.selectNewFeedFilterRuleCount, selectExistingFeedFilterRuleCount = _b.selectExistingFeedFilterRuleCount;
export var selectShowAdvancedFilters = function (state) {
    var _a;
    return (!!((_a = selectUserData(state)) === null || _a === void 0 ? void 0 : _a.use_advanced_filters) ||
        selectNewFeedFilterRuleCount(state) > 1);
};
export var selectFeedFilterRule = function (state, ruleIndex) { return selectNewFeedFilters(state)[ruleIndex]; };
export var selectFeedFilterCondition = function (state, ruleIndex, conditionIndex) { var _a; return (_a = selectFeedFilterRule(state, ruleIndex)) === null || _a === void 0 ? void 0 : _a[conditionIndex]; };
export var selectFeedFilterConditionCount = function (state, ruleIndex) { var _a; return ((_a = selectFeedFilterRule(state, ruleIndex)) === null || _a === void 0 ? void 0 : _a.length) || 0; };
export var selectMatchTypesForRule = createSelector([selectFeedFilterRule], function (rule) { return rule.map(function (condition) { return condition.match; }).filter(isTruthy); });
export var conditionIsPending = function (condition) {
    return !(condition === null || condition === void 0 ? void 0 : condition.item) || (matchTypes(textMatchTypes).matches(condition)
        ? !(condition.item[0].id.length >= 3)
        : condition.match === "dates" && condition.item[0].id === "custom"
            ? !(condition.startDate && condition.endDate)
            : !(condition.is === "is_none" || condition.item.length > 0));
};
export var selectConditionIsPending = createSelector([selectFeedFilterCondition], function (condition) { return conditionIsPending(condition); });
export var selectRuleIsPending = createSelector([selectFeedFilterRule], function (rule) { return rule.some(function (condition) { return conditionIsPending(condition); }); });
export var selectConditionPageId = createSelector([
    selectFeedFilterRule,
    function (_state, _ruleIndex, conditionIndex) { return conditionIndex; }
], function (rule, conditionIndex) {
    var _a, _b, _c, _d;
    if (((_a = rule === null || rule === void 0 ? void 0 : rule[conditionIndex]) === null || _a === void 0 ? void 0 : _a.match) === "locations") {
        return (_d = (_c = (_b = rule.find(function (condition) { return condition.match === "retailers"; })) === null || _b === void 0 ? void 0 : _b.item) === null || _c === void 0 ? void 0 : _c.map(function (item) { return item.id; })) === null || _d === void 0 ? void 0 : _d.join(",");
    }
});
export var addPrefetchedDataListener = function (startAppListening) {
    startAppListening({
        type: "FILTERS_CHECK_RESULTS_COUNT_PHOTOS_DATA",
        effect: function (action, _a) {
            var getState = _a.getState;
            var prefetchedData = getState().feedFilterRules.prefetchedData;
            prefetchedData === null || prefetchedData === void 0 ? void 0 : prefetchedData.data.slice(0, 8).forEach(function (photo) { return preloadPhoto(photo.img); });
        }
    });
};
