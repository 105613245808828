var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b;
import { MODAL_COMPONENTS } from "components/modal/Modal.config";
import { FILTER_RULES_FULL_WIDTH_BREAKPOINT } from "components/feed/filter-rules/FilterRules.config";
import { FaChartPie, FaFingerprint, FaDollarSign } from "react-icons/fa";
export var Modals = __assign({}, Object.keys(MODAL_COMPONENTS)
    .reduce(function (acc, cur) {
    var _a;
    return (__assign(__assign({}, acc), (_a = {}, _a[cur] = cur, _a)));
}, {}));
export var STATIC_MODAL_TITLES = {
    "ANALYTICS_LEARN_MORE": "About this report",
    "POWERPOINT_EXPORT": "Choose your slide layout",
    "COLLECTION_SHOW_COVERAGE_MODEL": "Sampling methodology",
    "CREATE_COLLECTION": "Create a new collection",
    "CREATE_NEW_PC": "Create a new private space on Shelfgram",
    "CREATE_PIPELINE_COLLECTION": "Create new pipeline collection",
    "DELETE_QUESTION_CODE": "Delete question code",
    "DESIGN_YOUR_CARDS": "Design your cards",
    "EASTER_EGG_SHOW_SUPER_MARIO": "Oh yeah! Mario time!",
    "EDIT_PROFILE_PIC": "Change the profile picture?",
    "EMAIL_DOMAIN": "Add an email domain",
    "FAILED_UPLOAD": "Uh oh!",
    "FEED_FILTER_SETTINGS": "Filter settings",
    "FILTER_MAP_BY_RETAILER": "Filter map to specific retailers",
    "GIVE_FEEDBACK": "Send feedback to our team",
    "HANDLE_INVITE_TEAM": "Invite your team to join Shelfgram",
    "HAWKEYE_INSTRUCTIONS": "Hawkeye instructions",
    "KEYBOARD_SHORTCUTS": "Keyboard shortcuts",
    "LINK_TO_PAGE": "Share a link to this page",
    "LOGIN_DISABLED": "Access disabled",
    "NEW_QUESTION_CODE": "Create new question code",
    "PHOTO_RESPONSE_GROUP": "Photo response group",
    "PLANOGRAM_FILTERS": "Filter planograms",
    "PRICE_ASSORTMENT": "Price Assortment",
    "PRICING_FILTER_DATE": "Filter for specific dates",
    "PRICING_FILTER_FEATURES": "Filter for specific pricing features",
    "PRICING_FILTER_LOCATION": "Filter for specific locations",
    "PRICING_FILTER_POSITION": "Filter for specific planogram positions",
    "PRICING_FILTER_PRICE": "Filter for specific prices",
    "PRICING_FILTER_PRODUCT": "Filter for specific products",
    "REMOVE_FROM_COLLECTION": "Remove this photo from the collection?",
    "REPORT": "Report a photo",
    "QUESTION_CODE_FILTERS": "Filter question codes",
    "SEND_APP_TO_YOUR_PHONE": "Send app to your phone",
    "SHARE_OF_SHELF": "Share of Shelf",
    "SHOW_VIEWER_BLOB": "Text blob in this photo",
    "STARRED_VIEWS_INSTRUCTIONS": "Save your filters as Starred views!",
    "STAR_A_VIEW": "Star this view",
    "STUDIO_ADD_TEXT": "Add text",
    "STUDIO_TIPS": "Getting started with Shelfgram Studio",
    "SWITCH_PRIVATE_SPACE": "Select a private space",
    "TRENDS_INFO": "Shelfgram Trends",
    "TRENDS_VISUALS": "Sample visuals",
    "UPGRADE_FLASH": "Upgrade to Shelfgram Enterprise",
};
export var WIDE_MODALS = [
    Modals.ANALYTICS_LEARN_MORE,
    Modals.EASTER_EGG_SHOW_SUPER_MARIO,
    Modals.SHARE_OF_SHELF,
    Modals.PRICE_ASSORTMENT
];
export var MODAL_FULL_SCREEN_BREAKPOINTS = (_a = {},
    _a[Modals.FEED_FILTERS] = FILTER_RULES_FULL_WIDTH_BREAKPOINT,
    _a);
export var PLANOGRAM_ANALYTICS_MODALS = (_b = {},
    _b[Modals.WORD_CLOUD] = {
        title: "Word Cloud",
        component: Modals.WORD_CLOUD,
        icon: FaFingerprint,
        isBeta: false
    },
    _b[Modals.SHARE_OF_SHELF] = {
        title: "Share of Shelf",
        component: Modals.SHARE_OF_SHELF,
        icon: FaChartPie,
        isBeta: true
    },
    _b[Modals.PRICE_ASSORTMENT] = {
        title: "Price Assortment",
        component: Modals.PRICE_ASSORTMENT,
        icon: FaDollarSign,
        isBeta: true
    },
    _b);
