import { clone, cloneDeep } from "lodash";
import { showModal, hideModal } from "@redux/slices/modalSlice";
import { collectionCountryUpdated, collectionCurationUpdated } from "@redux/slices/curatedCollectionsSlice";
import { photosLinkedToCollections, photoUnlinkedFromCollection } from "@redux/slices/userCollectionsSlice";
import {
  enterUserImpersonation,
  exitUserImpersonation,
  toggleBetaFeatures,
  updateSyndicatedCollectionPromotion
} from "@redux/api/endpoints/admin";
import {
  updatePrivateCollectionDashboard,
  updateFeatureGate,
  fetchTeamMembers
} from "@redux/api/endpoints/privateCollections";
import { checkAccessToCollection } from "@redux/api/endpoints/collections";
import { fetchInitialLoad } from "@redux/api/endpoints/search";
import {
  addPhotoView,
  addTagsToPhoto,
  fetchPhotoByImg,
  fetchPhotosByShareLink,
  removeTagsFromPhoto
} from "@redux/api/endpoints/photos";
import {
  addTagsToFlyer,
  fetchFlyerByImg,
  fetchFlyersByShareLink,
  removeTagsFromFlyer
} from "@redux/api/endpoints/flyers";
import { logFeatureFound, toggleAdvancedFilters } from "@redux/api/endpoints/users";
import { isAllOptionSelectors, isNoneOptionSelectors } from "components/feed/filter-rules/FilterRules.config";
import {
  feedFilterConditionAdded,
  feedFilterConditionRemoved,
  feedFilterMapUpdated
} from "@redux/slices/feedFilterRulesSlice";
import { prefetchedPhotosApplied } from "@redux/slices/feedSlice";

const defaultRviSettings = {
  category_availability: { toggle: "main_section" },
  brand_support_by_retailer: {
    excludedRetailers: [],
  },
  brand_interaction: {
    columnsToShow: {
      number: 10,
      id: "top_10",
      name: "Top 10",
    },
  },
  planogram_checks: {
    expanded_filter: "retailers",
    new: {},
    existing: {},
    resultsCount: null,
    filtersLastUpdated: null,
    showDatePicker: false,
    sort: "newest",
  },
  discoverability: {
    retailer: {
      name: "All Retailers",
      id: "all_retailers",
    },
  },
  leaders_by_geography: {
    toggle: "state",
    columnsToShow: {
      number: 10,
      id: "top_10",
      name: "Top 10",
    },
  },
  leaders_by_retailer: {
    toggle: "table",
    columnsToShow: {
      number: 10,
      id: "top_10",
      name: "Top 10",
    },
  },
  leaders_by_urbanization: {
    columnsToShow: {
      number: 10,
      id: "top_10",
      name: "Top 10",
    },
  },
  look_of_success: { toggle: "main_section" },
  anomaly_detection: { week: "latest" },
  national_visibility: {
    toggle: "overall",
    filters: {
      id: "rvi",
      name: "Overall",
    },
  },
  presence: {
    toggle: "main_section",
    week: "latest",
    columnsToShow: {
      number: 30,
      id: "top_30",
      name: "Top 30",
    },
  },
};

// reducers.js
const initialFetchReducerState = {
    fetching: false,
    error: null,
    component: null,
};

export const fetchReducer = (
  state = initialFetchReducerState,
  action
) => {
  switch (action.type) {
    case "API_CALL_REQUEST":
      return {
        ...state,
        fetching: true,
        error: null,
        component: action.payload?.component
      };
    case "API_CALL_TAKE_LATEST":
      return {
        ...state,
        fetching: true,
        error: null,
        component: action.payload?.component
      };
    case "API_CALL_SUCCESS":
      return {
        ...state,
        fetching: false,
        error: false,
        component: action.payload?.component
      };
    case "API_CALL_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        component: action.payload?.component
      };
    case "API_CALL_RESET":
      return state.component === action.payload
        ? initialFetchReducerState
        : state;
    default:
      return state;
  }
};

const defaultFeedMultiplePhotoSelect = {};

export const feedMultiplePhotoSelect = (
  state = defaultFeedMultiplePhotoSelect,
  action
) => {
  switch (action.type) {
    case "DELETE_DATA":
    case fetchPhotosByShareLink.fulfilled.type:
    case fetchFlyersByShareLink.fulfilled.type:
    case "LOGOUT":
    case "RESET_SELECT_MULTIPLE_PHOTOS":
      return defaultFeedMultiplePhotoSelect;
    case "REMOVE_SPECIFIC_SELECTED_PHOTO":
      return {
        date: new Date(),
        data: state.data.filter((x, i) => i !== action.data),
      };
    case "SELECT_MULTIPLE_PHOTOS_ALL":
      return { date: new Date(), data: action.data };
    case "EDIT_SELECT_MULTIPLE_PHOTOS":
      if (!state.data || state.data.length == 0) {
        return { date: new Date(), data: [action.data] };
      } else {
        let strippedState = state.data || [];
        strippedState = strippedState.filter(
          (item) => item.id !== action.data.id
        );
        if (state.data && strippedState.length == state.data.length) {
          return { date: new Date(), data: strippedState.concat(action.data) };
        } else {
          return { date: new Date(), data: strippedState };
        }
      }
    default:
      return state;
  }
};

export const feedMultiplePlanogramSelect = (state = {}, action) => {
  switch (action.type) {
    case "RESET_SELECT_MULTIPLE_PLANOGRAMS":
    case "LOGOUT":
      return {};
    case "REMOVE_SPECIFIC_SELECTED_PLANOGRAM":
      return {
        date: new Date(),
        data: state.data.filter((x, i) => i !== action.data),
      };
    case "SELECT_MULTIPLE_PLANOGRAMS_ALL":
      return { date: new Date(), data: action.data };
    case "EDIT_SELECT_MULTIPLE_PLANOGRAMS":
      if (!state.data) {
        return { date: new Date(), data: [action.data] };
      } else {
        let strippedState = state.data || [];
        strippedState = strippedState.filter(
          (item) => item.id !== action.data.id
        );
        if (state.data && strippedState.length == state.data.length) {
          return { date: new Date(), data: strippedState.concat(action.data) };
        } else {
          return { date: new Date(), data: strippedState };
        }
      }
    default:
      return state;
  }
};

const defaultFeedMultipleFlyerSelect = {};

export const feedMultipleFlyerSelect = (state = {}, action) => {
  switch (action.type) {
    case "DELETE_DATA":
    case fetchPhotosByShareLink.fulfilled.type:
    case fetchFlyersByShareLink.fulfilled.type:
    case "LOGOUT":
    case "RESET_SELECT_MULTIPLE_FLYERS":
      return defaultFeedMultipleFlyerSelect;
    case "REMOVE_SPECIFIC_SELECTED_FLYER":
      return {
        date: new Date(),
        data: state.data.filter((x, i) => i !== action.data),
      };
    case "SELECT_MULTIPLE_FLYERS_ALL":
      return {
        date: new Date(),
        data: action.data,
      };
    case "EDIT_SELECT_MULTIPLE_FLYERS":
      if (!state.data || state.data.length == 0) {
        return { date: new Date(), data: [action.data] };
      } else {
        let strippedState = state.data || [];
        strippedState = strippedState.filter(
          (item) => item.id !== action.data.id
        );
        if (state.data && strippedState.length == state.data.length) {
          return { date: new Date(), data: strippedState.concat(action.data) };
        } else {
          return { date: new Date(), data: strippedState };
        }
      }
    default:
      return state;
  }
};

export const showFeedShiftMultipleSelect = (state = false, action) => {
  switch (action.type) {
    case "SELECT_MULTIPLE_PLANOGRAMS_ALL":
    case "SHOW_FEED_SHIFT_MULTIPLE_SELECT":
      return true;
    case "@@router/LOCATION_CHANGE":
    case "DELETE_DATA":
    case "RESET_SELECT_MULTIPLE_PHOTOS":
    case "RESET_SELECT_MULTIPLE_FLYERS":
    case "HIDE_FEED_SHIFT_MULTIPLE_SELECT":
      return false;
    default:
      return state;
  }
};

export const showHeader = (state = false, action) => {
  switch (action.type) {
    case "SHOW_HEADER":
      return true;
    case "LOGOUT":
    case "HIDE_HEADER":
      return false;
    default:
      return state;
  }
};

export const showUploadProgress = (state = false, action) => {
  switch (action.type) {
    case "SHOW_UPLOAD_PROGRESS":
      return true;
    case "HIDE_UPLOAD_PROGRESS":
      return false;
    default:
      return state;
  }
};

export const showDropzone = (state = false, action) => {
  switch (action.type) {
    case "SHOW_DROPZONE":
      return true;
    case "HIDE_DROPZONE":
      return false;
    default:
      return state;
  }
};

export const showTagging = (state = false, action) => {
  switch (action.type) {
    case "SHOW_TAGGING":
      return true;
    case "VIEWER_SHOW_COLLECTIONS":
    case "VIEWER_SHOW_STUDIO":
    case "VIEWER_SHOW_COMMENTS":
    case "VIEWER_SHOW_MAP":
    case "VIEWER_SHOW_FULLSCREEN":
    case "SHOW_TEXT_ANNOTATIONS":
    case "SHOW_HEATMAP":
    case "HIDE_VIEWER":
    case "HIDE_TAGGING":
    case "LOGOUT":
      return false;
    default:
      return state;
  }
};

export const dataUser = (state = null, action) => {
  switch (action.type) {
    case "SHOW_BIG_ANNOUNCEMENT":
      return { ...state, big_announcement_seen: new Date() };
    case "WEBSOCKETS/update_pc_banner_message":
      return {
        ...state,
        pc_banner_message: action.data,
      };
    case "TOGGLE_HORIZONTAL_TIMELINE":
      return {
        ...state,
        timeline_horizontal: action.data,
      };
    case "CLEAR_NEW_NOTIFICATIONS":
      return { ...state, notifications: { unread: null, read: action.data } };
    case "WEBSOCKETS/add_notification":
      return {
        ...state,
        notifications: state.notifications
          ? {
              ...state.notifications,
              unread: state.notifications.unread
                ? [action.data].concat(state.notifications.unread)
                : [action.data],
            }
          : {
              unread: [action.data],
            },
      };
    case "USER_SELECT_HOMEPAGE_PREFERENCE":
      return { ...state, homepage_preference: action.data };
    case "TURN_ON_DARK_MODE":
      return { ...state, dark_mode: true };
    case "TURN_OFF_DARK_MODE":
      return { ...state, dark_mode: false };
    case feedFilterConditionAdded.type:
      return {
        ...state,
        shown_filter_how_to: true,
      };
    case "USER_CHECK_ACCESS_PLANOGRAMS_DATA":
      return {
        ...state,
        access_planograms: { last_updated: new Date(), data: action.data },
      };
    case "USER_CHECK_ACCESS_PRICE_CHECKS_DATA":
      return {
        ...state,
        access_pricing: { last_updated: new Date(), data: action.data },
      };
    case "USER_CHECK_ACCESS_ANALYTICS_DATA":
      return {
        ...state,
        access_analytics: { last_updated: new Date(), data: action.data },
      };
    case "SWITCH_PRIVATE_SPACE":
      return {
        ...state,
        access_pricing: undefined,
        access_analytics: undefined,
        access_planograms: undefined,
      };
    case "STARRED_PAGES_DATA":
      return {
        ...state,
        starred: action.data,
      };
    case "STAR_CHECK_ON":
      let currentStars = cloneDeep(state.starred || []);
      currentStars.unshift(action.data);
      return {
        ...state,
        starred: currentStars,
      };
    case "STAR_BUTTON_CHECK_OFF":
    case "STAR_CHECK_OFF":
      return {
        ...state,
        starred: (state.starred || []).filter(
          (x) => x.pathname !== action.data
        ),
      };
    case "USER_SET_PPT_LOGO_PREFERENCE_OFF":
      return {
        ...state,
        ppt_logo: true,
      };
    case "USER_SET_PPT_LOGO_PREFERENCE_ON":
      return {
        ...state,
        ppt_logo: false,
      };
    case "USER_SET_PPT_COMPRESSION_PREFERENCE_HIGH": {
      return { ...state, ppt_high_res: false };
    }
    case "USER_SET_PPT_COMPRESSION_PREFERENCE_LOW": {
      return { ...state, ppt_high_res: true };
    }
    case "USER_SET_CARD_PREF_ENABLED_ACTION": {
      return { ...state, card_pref_share: false };
    }
    case "USER_SET_CARD_PREF_DISABLED_ACTION": {
      return { ...state, card_pref_share: true };
    }
    case "USER_SET_CARD_PREF_ENABLED_TIME": {
      return { ...state, card_pref_specific_time: true };
    }
    case "USER_SET_CARD_PREF_DISABLED_TIME": {
      return { ...state, card_pref_specific_time: false };
    }
    case "USER_SET_CARD_PREF_DISABLED_TIMEAGO": {
      return { ...state, card_pref_time_ago: false };
    }
    case "USER_SET_CARD_PREF_ENABLED_TIMEAGO": {
      return { ...state, card_pref_time_ago: true };
    }
    case "USER_SET_CARD_PREF_DISABLED_STORE": {
      return { ...state, card_pref_store: false };
    }
    case "USER_SET_CARD_PREF_ENABLED_STORE": {
      return { ...state, card_pref_store: true };
    }
    case "USER_SET_CARD_PREF_DISABLED_CITY": {
      return { ...state, card_pref_city: false };
    }
    case "USER_SET_CARD_PREF_ENABLED_CITY": {
      return { ...state, card_pref_city: true };
    }
    case "USER_SET_CARD_PREF_DISABLED_STATE": {
      return { ...state, card_pref_state: false };
    }
    case "USER_SET_CARD_PREF_ENABLED_STATE": {
      return { ...state, card_pref_state: true };
    }
    case "USER_SET_CARD_PREF_DISABLED_COUNTRY": {
      return { ...state, card_pref_country: false };
    }
    case "USER_SET_CARD_PREF_ENABLED_COUNTRY": {
      return { ...state, card_pref_country: true };
    }
    case "USER_SELECT_FONT_SIZE": {
      return { ...state, font_size_prefix: action.data };
    }
    case "USER_SELECT_LANGUAGE": {
      return { ...state, language_prefix: action.data };
    }
    case "USER_CHECK_PRICING_ACCESS_DATA":
      return {
        ...state,
        pricing_access: {
          last_updated: new Date(),
          show_facings: action.data.filter((x) => x.show_facings).length > 0,
          data_as_arr: action.data,
          data: action.data.reduce(function (result, item, index, array) {
            result[item.id] = item;
            return result;
          }, {}),
        },
      };
    case "USER_CHECK_PLANOGRAM_ACCESS_DATA":
      return {
        ...state,
        planogram_access: {
          last_updated: new Date(),
          data_as_arr: action.data,
          data: action.data.reduce(function (result, item, index, array) {
            result[item.id] = item;
            return result;
          }, {}),
        },
      };
    case "USER_SELECT_CONTRAST":
      return { ...state, contrast_prefix: action.data };
    case "USER_SET_PREFERENCE_PRICING_MASS":
      return { ...state, pricing_preference_mass: true };
    case "USER_SET_PREFERENCE_PRICING_CLUB":
      return { ...state, pricing_preference_mass: false };
    case "USERS_GET_INTERESTS_COMPANIES_DATA":
      return { ...state, company_interests: action.data };
    case "USER_LOGS_IN":
      return null;
    case fetchTeamMembers.fulfilled.type:
      return { ...state, team: action.payload };
    case "USER_REFRESH_USING_SHELF_TOKEN_DATA":
    case "USER_AUTH_VIA_SOCIAL_SIGN_ON_DATA":
    case "USER_LOGS_IN_DATA":
      return action.data;
    case enterUserImpersonation.fulfilled.type:
    case exitUserImpersonation.fulfilled.type:
      return action.payload;
    case "HIDE_ORIENTATION":
      if (state) {
        return { ...state, show_onboarding: false };
      }
      return state;
    case "USER_SET_SORT_PREFERENCE":
      if (state) {
        return { ...state, sort_preference: action.data };
      }
      return state;
    case "USER_DISCOVERED_CLICK_TO_ZOOM":
      if (state) {
        return { ...state, feature_found_click_to_zoom: true };
      }
      return state;
    case "USER_COMPLETE_PROFILE_NAME_DATA":
      if (state && action.data.length > 0) {
        return {
          ...state,
          first_name: action.data[0].first_name,
          last_name: action.data[0].last_name,
          show_onboarding: true,
        };
      }
      return action.data;
    case "USER_SET_FEED_LAYOUT":
      if (state) {
        return { ...state, cards_in_layout: action.data };
      }
      return state;
    case "USER_SET_FLYER_LAYOUT":
      if (state) {
        return { ...state, cards_in_flyer_layout: action.data };
      }
      return state;
    case "CHECK_RECENT_UPLOADS_DATA":
      if (state) {
        return { ...state, photo_uploads: action.data };
      }
      return state;
    case "CHECK_RECENT_SHARE_LINKS_DATA":
      if (state) {
        return { ...state, share_links: action.data };
      }
      return state;
    case "FEATURE_FOUND_PHOTO_SHARING_DATA":
      if (state) {
        return { ...state, feature_found_photo_sharing: true };
      }
      return state;
    case "FEATURE_FOUND_FOLLOWING_FEED_DATA":
      if (state) {
        return { ...state, feature_found_following_feed: true };
      }
      return state;
    case "FEATURE_FOUND_TIPS_AND_TRICKS_DATA":
      if (state) {
        return { ...state, feature_found_tips_and_tricks: true };
      }
      return state;
    case "FEATURE_FOUND_CREATE_A_COLLECTION_DATA":
      if (state) {
        return { ...state, feature_found_create_a_collection: true };
      }
      return state;
    case "FEATURE_FOUND_ORDER_PHOTOS_DATA":
      if (state) {
        return { ...state, feature_found_order_photos: true };
      }
      return state;
    case "FEATURE_FOUND_FLYERS_DATA":
      if (state) {
        return { ...state, feature_found_flyers: true };
      }
      return state;
    case logFeatureFound.fulfilled.type:
      if (state) {
        return {
          ...state,
          [`feature_found_${action.meta.arg.componentSuffix.toLowerCase()}`]: true
        }
      }
      return state;
    case "FEATURE_FOUND_MULTIPLE_FILTERS_DATA":
      if (state) {
        return { ...state, feature_found_multiple_filters: true };
      }
      return state;
    case "TOGGLE_USER_POWERPOINT_PREFERENCE":
      return { ...state, powerpoint_preference: action.data };
    case "UPDATE_PC_LIST_WITH_REQUESTED":
      if (state) {
        if (state.private_collections_requested) {
          const newPcList = state.private_collections_requested;
          newPcList.push(action.data);
          return { ...state, private_collections_requested: newPcList };
        } else {
          return {
            ...state,
            private_collections_requested: [action.data],
          };
        }
      }
      return state;
    case "LANDING_PAGE_ABOUT_DATA":
      if (state && state.plan_id === 1) {
        if (
          action.data &&
          action.data.length > 0 &&
          action.data[0].route === "private_collection" &&
          !action.data[0].downgraded &&
          action.data[0].collection_users
        ) {
          return { ...state, plan_id: 3 };
        }
      }
      return state;
    case "PRIVATE_COLLECTION_DATA":
      if (state && state.plan_id === 1) {
        const inActivePC = action.data.filter((x) => x.active && !x.downgraded);
        if (inActivePC.length > 0) {
          return { ...state, plan_id: 3 };
        }
      }
      return state;
    case "FOLLOW_THE_PAGE_LOCALLY":
      if (!state) {
        return { following: action.data };
      }
      // Remove from following suggestions if it exists
      let suggestedFollowing = cloneDeep(state.suggestions);
      if (suggestedFollowing) {
        suggestedFollowing = suggestedFollowing.filter(
          (item) => item.id !== action.data.id
        );
      }
      const newFollowingItems = state.following || [];
      // If exists, set following to true
      const itemExists = newFollowingItems.filter(
        (x) => x.id == action.data.id
      );
      if (itemExists.length > 0) {
        const itemIndex = newFollowingItems.indexOf(itemExists[0]);
        newFollowingItems[itemIndex].following = true;
        return Object.assign({}, state, {
          following: newFollowingItems,
          suggestions: suggestedFollowing,
        });
      }
      newFollowingItems.unshift(action.data);
      return {
        ...state,
        following: newFollowingItems,
        suggestions: suggestedFollowing,
      };
    case "UNFOLLOW_THE_PAGE_LOCALLY":
      let removeFollow = cloneDeep(state.following) || [];
      const itemToUnfollow = removeFollow.filter(
        (x) => x.id == action.data.id
      )[0];
      const removeIndex = removeFollow.indexOf(itemToUnfollow);
      if (removeFollow[removeIndex]) {
        removeFollow[removeIndex].following = false;
        return Object.assign({}, state, {
          following: removeFollow,
        });
      }
      return state;
    case "USER_FOLLOWING_SUGGESTIONS_DATA":
      const currentFollowingIds = state.following.map((x) => x.id);
      return {
        ...state,
        suggestions: action.data.filter(
          (item) => !currentFollowingIds.includes(item.id)
        ),
      };
    case "USER_FOLLOWING_PREFERENCES_DATA":
      if (state.following) {
        let newFollowingData = [];
        const newFollowingDataMap = new Map();
        for (const item of action.data) {
          if (!newFollowingDataMap.has(item.id)) {
            newFollowingDataMap.set(item.id, true); // set any value to Map
            newFollowingData.push(item);
          }
        }
        return Object.assign({}, state, {
          loadedFollowing: true,
          following: newFollowingData,
        });
      }
      return {
        ...state,
        loadedFollowing: true,
        following: action.data.sort(() => 0.5 - Math.random()),
      };
    case "UPLOAD_PHOTOS_ADD_TO_RECENT":
      if (state.photo_uploads) {
        return {
          ...state,
          uploaded_photos: true,
          photo_uploads: {
            ...state.photo_uploads,
            data: [action.data].concat(state.photo_uploads.data),
          },
        };
      } else {
        return {
          ...state,
          uploaded_photos: true,
          photo_uploads: { data: [action.data], page: 0 },
        };
      }
    case "USER_EMAIL_PREFERENCES_EMAIL_ME_MONTHLY_PHOTOS":
      return { ...state, email_me_monthly_photos: action.data };
    case "USER_EMAIL_PREFERENCES_EMAIL_ME_NEW_FEATURES":
      return { ...state, email_me_new_features: action.data };
    case "LOGOUT":
      return null;
    case "ACCEPT_TERMS_LOCALLY":
      return Object.assign({}, state, { accepted_latest_terms: true });
    case "USER_DATA":
      if (!action.data.error && action.data.length > 0) {
        return {
          ...state,
          ...action.data[0],
        };
      }
      return state;
    case "USER_DATA_SET_SAVED_TRUE":
      if (state) {
        return {
          ...state,
          saved_photos: true,
        };
      }
      return state;
    case "ADD_TO_UNSAVED":
      if (action.data.resetSaved) {
        return Object.assign({}, state, { saved_photos: false });
      }
      return state;
    case "USER_CHANGE_LOCAL_NAME":
      return Object.assign({}, state, {
        first_name: action.data.first_name,
        last_name: action.data.last_name,
      });
    case "USER_CHANGE_LOCAL_EMAIL":
      return Object.assign({}, state, { email: action.data.email });
    case "CHECK_ACCESS_DATA":
      return { ...state, private_collections: action.data.access };
    case toggleBetaFeatures.pending.type:
      return { ...state, show_beta_features: action.meta.arg.body.active };
    case toggleBetaFeatures.rejected.type:
      return { ...state, show_beta_features: !action.meta.arg.body.active };
    case toggleAdvancedFilters.pending.type:
      return { ...state, use_advanced_filters: action.meta.arg.body.active };
    case toggleAdvancedFilters.rejected.type:
      return { ...state, use_advanced_filters: !action.meta.arg.body.active };

    default:
      return state;
  }
};

export const dataPendingUpload = (state = null, action) => {
  switch (action.type) {
    case "UPLOAD_PHOTOS_DELETE_ONE":
      return {
        ...state,
        photos: state.photos.filter(item => item.name !== action.data.name)
      };
    case "UPLOAD_PHOTOS_CREATE_UPLOAD_ID_DATA":
      return {
        ...state,
        uploadId: action.data,
      };
    case "UPLOAD_PHOTOS_SEND_DATA_TO_API_DATA":
      return {
        ...state,
        completed: [...state.completed, action.data.name]
      };
    case "PENDING_UPLOAD_DROP_PHOTOS":
      return {
        photos: action.data,
        completed: [],
      };
    case "CREATE_A_RETAILER_DATA":
      return Object.assign({}, state, {
        search: action.data.concat(state.search),
      });
    case "UPLOAD_PHOTOS_RETAILER_SEARCH_DATA":
      if (Array.isArray(action.data)) {
        return {
          ...state,
          search: action.data,
        };
      }
      return {
        ...state,
        search: [],
      };
    case "UPLOAD_PHOTOS_SET_RETAILER":
      return {
        ...state,
        retailer: action.data,
      };
    case "UPLOAD_PHOTOS_SET_DESTINATION":
      return {
        ...state,
        destination: action.data,
      };
    case "RESET_PENDING_UPLOAD":
      return null;
    default:
      return state;
  }
};

export const dataLoginPreview = (state = false, action) => {
  switch (action.type) {
    case "LOGIN_PREVIEW_DATA":
      return action.data;
    default:
      return state;
  }
};

export const dataPhotosForReviewer = (state = {}, action) => {
  switch (action.type) {
    case "GET_PHOTOS_FOR_REVIEWER_DATA":
      return action.data;
    case "APPROVE_OR_REJECT_PHOTO_DATA":
      return {
        ...state,
        count: {
          count: state.count.count - 1,
        },
      };
    default:
      return state;
  }
};

export const dataGenerateReportPage = (state = null, action) => {
  switch (action.type) {
    case "GENERATE_A_REPORT":
      return action.data && action.data.photos.data;
    case "GENERATE_A_REPORT_FROM_SHARE_PAGE":
      return action.data;
    default:
      return state;
  }
};

export const dataPreviewPhotoOptions = (state = null, action) => {
  switch (action.type) {
    case "SEARCH_FOR_PREVIEW_PHOTO_OPTIONS_DATA":
      return action.data;
    default:
      return state;
  }
};

export const dataRecentSearches = (state = null, action) => {
  switch (action.type) {
    case "LOGOUT":
      return null;
    case "RECENT_SEARCHES_DATA":
      if (action.data && action.data.error) {
        return state;
      } else {
        return { date: new Date(), data: action.data };
      }
    case "RECENT_SEARCHES_ADD":
      if (state?.data?.length) {
        if (state.data.length > 4) {
          const newData = [action.data, ...state.data.slice(0, state.data.length - 1)];
          return { date: new Date(), data: newData };
        }
        return { date: new Date(), data: [action.data].concat(state.data) };
      }
      return { date: new Date(), data: [action.data] };
    case "RECENT_SEARCHES_MOVE_TO_FRONT":
      return {
        date: new Date(),
        data: [
          action.data,
          ...state.data.filter((x) => x.id !== action.data.id),
        ],
      };
    default:
      return state;
  }
};

export const dataTrendingSearches = (state = null, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const dataDirectory = (state = {}, action) => {
  switch (action.type) {
    case fetchInitialLoad.fulfilled.type:
      const { data, last_updated } = action.payload;
      return {
        ...state,
        last_updated,
        countries: { data: data.countries, page: 0, more: false },
        regions: { data: data.regions, page: 0, more: false },
        categories: { data: data.categories, page: 0, more: false },
        channels: { data: data.channels, page: 0, more: false },
        tags: { data: data.tags, page: 0, more: false },
      };
    case "DIRECTORY_COUNTRIES_DATA":
      return {
        ...state,
        countries: {
          data: action.data.data,
          page: action.data.page,
          more: action.data.more,
        },
      };
    case "DIRECTORY_TAGS_DATA":
      if (state.tags && state.tags.data && action.data.page !== 0) {
        return {
          ...state,
          tags: {
            data: state.tags.data.concat(action.data.data),
            page: action.data.page,
            more: action.data.more,
          },
        };
      }
      return {
        ...state,
        tags: {
          data: action.data.data,
          page: action.data.page,
          more: action.data.more,
        },
      };
    case "DIRECTORY_CATEGORIES_DATA":
      if (state.categories && state.categories.data && action.data.page !== 0) {
        return {
          ...state,
          categories: {
            data: state.categories.data.concat(action.data.data),
            page: action.data.page,
            more: action.data.more,
          },
        };
      }
      return {
        ...state,
        categories: {
          data: action.data.data,
          page: action.data.page,
          more: action.data.more,
        },
      };
    case "DIRECTORY_CHANNELS_DATA":
      if (state.channels && state.channels.data && action.data.page !== 0) {
        return {
          ...state,
          channels: {
            data: state.channels.data.concat(action.data.data),
            page: action.data.page,
            more: action.data.more,
          },
        };
      }
      return {
        ...state,
        channels: {
          data: action.data.data,
          page: action.data.page,
          more: action.data.more,
        },
      };
    case "DIRECTORY_RETAILERS_DATA":
      return {
        ...state,
        retailers: action.data,
      };
    case "DIRECTORY_BRANDS_DATA":
      return {
        ...state,
        brands: action.data,
      };
    case "DIRECTORY_DATA_RESET":
      return {
        ...state,
        [action.data]: null,
      };
    default:
      return state;
  }
};

export const dataDirectorySettings = (
  state = {
    countries: {
      presetLanguages: [
        { id: 6019, value: "Arabic", label: "Arabic" },
        { id: 4601, value: "English", label: "English" },
        { id: 1683, value: "French", label: "French" },
        { id: 3875, value: "Italian", label: "Italian" },
        { id: 5808, value: "German", label: "German" },
        { id: 1171, value: "Spanish", label: "Spanish" },
        { id: 5788, value: "Russian", label: "Russian" },
        { id: 4524, value: "Portuguese", label: "Portuguese" },
        { id: 5048, value: "Hungarian", label: "Hungarian" },
        { id: 283, value: "Turkish", label: "Turkish" },
      ],
      sort: {
        id: "alphabeticalAz",
        value: "alphabeticalAz",
        label: "Alphabetical (A - Z)",
      },
    },
    malls: {
      sort: {
        id: "alphabeticalAz",
        value: "alphabeticalAz",
        label: "Alphabetical (A - Z)",
      },
    },
    channels: {
      sort: {
        id: "alphabeticalAz",
        value: "alphabeticalAz",
        label: "Alphabetical (A - Z)",
      },
    },
    categories: {
      sort: {
        id: "alphabeticalAz",
        value: "alphabeticalAz",
        label: "Alphabetical (A - Z)",
      },
      type: {
        id: 1,
        value: "All Types",
        label: "All Types",
        noImg: true,
      },
    },
    brands: {
      sort: {
        id: "popularHigh",
        value: "popularHigh",
        label: "Most popular",
      },
    },
    retailers: {
      sort: {
        id: "popularHigh",
        value: "popularHigh",
        label: "Most popular",
      },
    },
    companies: {
      sort: {
        id: "popularHigh",
        value: "popularHigh",
        label: "Most popular",
      },
    },
    tags: {
      sort: {
        id: "alphabeticalAz",
        value: "alphabeticalAz",
        label: "Alphabetical (A - Z)",
      },
    },
  },
  action
) => {
  switch (action.type) {
    case "DIRECTORY_COUNTRIES_SORT":
      return {
        ...state,
        countries: {
          ...state.countries,
          sort: action.data,
        },
      };
    case "DIRECTORY_COUNTRIES_FILTER_REGIONS":
      return {
        ...state,
        countries: {
          ...state.countries,
          regions: action.data,
        },
      };
    case "DIRECTORY_COUNTRIES_FILTER_LANGUAGES":
      return {
        ...state,
        countries: {
          ...state.countries,
          languages: action.data,
        },
      };
    case "DIRECTORY_COUNTRIES_FILTER_GOVERNMENT_TYPES":
      return {
        ...state,
        countries: {
          ...state.countries,
          governmentTypes: action,
        },
      };
    case "DIRECTORY_FINANCIAL_SERVICES_SORT":
      return {
        ...state,
        financial_services: {
          ...state.financial_services,
          sort: action.data,
        },
      };
    case "DIRECTORY_MALLS_SORT":
      return {
        ...state,
        malls: {
          ...state.malls,
          sort: action.data,
        },
      };
    case "DIRECTORY_CHANNELS_SORT":
      return {
        ...state,
        channels: {
          ...state.channels,
          sort: action.data,
        },
      };
    case "DIRECTORY_CATEGORIES_SORT":
      return {
        ...state,
        categories: {
          ...state.categories,
          sort: action.data,
        },
      };
    case "DIRECTORY_RETAILERS_SORT":
      return {
        ...state,
        retailers: {
          ...state.retailers,
          sort: action.data,
        },
      };
    case "DIRECTORY_BRANDS_SORT":
      return {
        ...state,
        brands: {
          ...state.brands,
          sort: action.data,
        },
      };
    case "DIRECTORY_COMPANIES_FILTER_CATEGORIES":
      return {
        ...state,
        brands: {
          ...state.brands,
          categories: action.data,
        },
      };
    case "DIRECTORY_COMPANIES_FILTER_CHANNELS":
      return {
        ...state,
        retailers: {
          ...state.retailers,
          channels: action.data,
        },
      };
    case "DIRECTORY_TAGS_SORT":
      return {
        ...state,
        tags: {
          ...state.tags,
          sort: action.data,
        },
      };
    case "DIRECTORY_CATEGORIES_FILTER_TYPE":
      return {
        ...state,
        categories: {
          ...state.categories,
          type: action.data,
        },
      };
    default:
      return state;
  }
};

export const dataCollectionsPrivate = (state = null, action) => {
  switch (action.type) {
    case "LOGOUT":
      return null;
    case "PRIVATE_COLLECTION_DATA":
      if (action.data && !action.data.error) {
        return action.data;
      } else {
        return state;
      }
    case updateFeatureGate.pending.type: {
      if (!state) return state;

      return state.map((collection) => {
        if (collection.id === action.meta.arg.body.landing_page_slug) {
          return {
            ...collection,
            feature_gates: collection.feature_gates.map(gate =>
              gate.id === action.meta.arg.body.feature_id
                ? { ...gate, is_enabled: action.meta.arg.body.is_enabled }
                : gate
            )
          };
        }

        return collection;
      });
    }

    case updateFeatureGate.rejected.type: {
      if (!state) return state;

      return state.map((collection) => {
        if (collection.id === action.meta.arg.body.landing_page_slug) {
          return {
            ...collection,
            feature_gates: collection.feature_gates.map(gate =>
              gate.id === action.meta.arg.body.feature_id
                ? { ...gate, is_enabled: !gate.is_enabled }
                : gate
            )
          };
        }

        return collection;
      });
    }
    default:
      return state;
  }
};

export const dataLandingPageAbout = (state = null, action) => {
  switch (action.type) {
    case "PRIVATE_SPACE_TOGGLE_DOWNGRADE":
      return {
        ...state,
        downgraded: action.data,
      };
    case "CHANGE_LOCATION_STORE_NUMBER":
      return {
        ...state,
        store_num: action.data,
      };
    case "SHOW_FEED_AS_RETAILER":
      return {
        ...state,
        show_as_retailer: true,
      };
    case "SHOW_FEED_AS_BRAND":
      return {
        ...state,
        show_as_retailer: false,
      };
    case "PRICING_INCREMENT_PAGE":
      return {
        ...state,
        pricing: {
          ...state.pricing,
          page: state.pricing.page + 1,
        },
      };
    case "SHOW_PRICING_DATE_SELECTOR_START":
      return {
        ...state,
        showDatePicker: "start",
      };
    case "SHOW_PRICING_DATE_SELECTOR_END":
      return {
        ...state,
        showDatePicker: "end",
      };
    case "HIDE_PRICING_DATE_SELECTOR":
      return {
        ...state,
        showDatePicker: false,
      };
    case "FACINGS_GET_FACETS_DATA":
      return {
        ...state,
        facings_facets: { retailer: action.data },
      };
    case "FACINGS_SEARCH_FIRST_DATA":
      return {
        ...state,
        facings: { ...action.data, date: new Date().toString() },
      };
    case checkAccessToCollection.fulfilled.type:
      if (action.meta.arg.routeParams.collectionId === state?.id) {
        return {
          ...state,
          access_check: true,
          access: action.payload.access,
        };
      }
      return state;
    case "SWITCH_PRIVATE_SPACE":
    case "CLEAR_ENTERPRISE_LOGO_IN_MENU":
      return {
        ...state,
        access_check: undefined,
        access: undefined
      }
    case "PRICING_FILTER_APPLY_FEATURES":
      return {
        ...state,
        pricing_features: action.data,
      };
    case "PRICING_FILTER_APPLY_LOCATION":
      return {
        ...state,
        pricing_location_type: action.data.location_type,
        pricing_location_selections: action.data.location_selections,
      };
    case "PRICING_FILTER_APPLY_PRODUCT":
      return {
        ...state,
        pricing_product: action.data,
      };
    case "PRICING_FILTER_APPLY_DATE":
      return {
        ...state,
        pricing_dates: action.data,
      };
    case "PRICING_SHOW_DATE_PICKER_START":
      return {
        ...state,
        show_date_picker_start: true,
      };
    case "PRICING_SHOW_DATE_PICKER_END":
      return {
        ...state,
        show_date_picker_end: true,
      };
    case "PRICING_HIDE_DATE_PICKER":
      return {
        ...state,
        show_date_picker_start: false,
        show_date_picker_end: false,
      };
    case "PRICING_FILTER_APPLY_PRICE":
      return {
        ...state,
        pricing_filter: action.data,
      };
    case "PRICING_FILTER_APPLY_POSITIONS":
      return {
        ...state,
        pricing_positions: action.data,
      };
    case "COLLECTION_GET_MAX_AND_MIN_PRICE_DATA":
      return {
        ...state,
        pricing_boundaries: action.data,
      };
    case "PRODUCT_PRICING_DATA":
      return {
        ...state,
        pricing: action.data,
      };
    case "PRODUCT_PRICING_MORE_DATA":
      const uniquePricing = [];
      const mapPricing = new Map();

      const allPricing = ((state.pricing && state.pricing.data) || []).concat(
        action.data.data
      );
      for (const itemFacing of allPricing) {
        if (!mapPricing.has(itemFacing.id)) {
          mapPricing.set(itemFacing.id, true); // set any value to Map
          uniquePricing.push(itemFacing);
        }
      }
      return {
        ...state,
        pricing: {
          ...action.data,
          data: uniquePricing,
        },
      };
    case "MAP_RETAILER_FILTERS":
      return {
        ...state,
        map_filters: action.data,
      };
    case "FOLLOW_THE_PAGE_LOCALLY":
      return {
        ...state,
        following: true,
      };
    case "UNFOLLOW_THE_PAGE_LOCALLY":
      return {
        ...state,
        following: false,
      };
    case "CHANGE_COMPANY_PARENTS":
      return {
        ...state,
        parents: action.data,
      };
    case "CHANGE_COMPANY_CATEGORIES":
      return {
        ...state,
        categories: action.data,
      };
    case "CHANGE_COMPANY_CHANNELS":
      return {
        ...state,
        channels: action.data,
      };
    case "PRIVATE_COLLECTION_TOGGLE_SHOW_DASHBOARD":
      const currentShowDashboard = state.show_dashboard;
      return {
        ...state,
        show_dashboard: !currentShowDashboard,
      };
    case collectionCurationUpdated.type:
      return {
        ...state,
        is_curated: action.payload.is_curated
      };
    case updateSyndicatedCollectionPromotion.pending:
      if (state?.id === action.meta.arg.body.collection_id) {
        return {
          ...state,
          is_promoted: !state.is_promoted
        }
      }
      return state;
    case "CREDIT_CARD_SUBMIT_DATA":
      return {
        ...state,
        ccof: true,
      };
    case "PRIVATE_COLLECTION_DASHBOARD_DATA":
      const { companies, tags, categories, channels } = action.data;
      const collections = action.data.collections
        .flatMap(item => item.data)
        .sort((a, b) => a.label < b.label ? -1 : 1);
      return {
        ...state,
        dashboard: {
          existing: { collections, companies, tags, categories, channels },
          new: { collections, companies, tags, categories, channels }
        }
      };
    case "PRIVATE_COLLECTION_DASHBOARD_EDIT_ITEMS":
      if (state) {
        const { itemType, items } = action.data;
        const itemData = items.map(({ id, value, source, img, route, background }) => {
          return { id, value, source, img, route, background }
        });
        return {
          ...state,
          dashboard: {
            ...state.dashboard,
            new: {
              ...state.dashboard.new,
              [itemType]: itemData
            }
          }
        };
      }
      return state;
    case "PRIVATE_COLLECTION_DASHBOARD_CANCEL_EDIT_ITEMS":
      if (state?.dashboard) {
        const { itemType } = action.data;
        return {
          ...state,
          dashboard: {
            ...state.dashboard,
            new: {
              ...state.dashboard.new,
              [itemType]: state.dashboard.existing[itemType]
            }
          }
        };
      }
      return state;
    case "PRIVATE_COLLECTION_DASHBOARD_ADD_COLLECTION":
      if (state?.dashboard) {
        return {
          ...state,
          dashboard: {
            ...state.dashboard,
            new: {
              ...state.dashboard.new,
              collections: [action.data, ...state.dashboard.new.collections]
            }
          }
        };
      }
      return state;
    case "PRIVATE_COLLECTION_DASHBOARD_REMOVE_COLLECTION":
      if (state?.dashboard) {
        return {
          ...state,
          dashboard: {
            ...state.dashboard,
            new: {
              ...state.dashboard.new,
              collections: state.dashboard.new.collections.filter(c => c.id !== action.data.id),
            }
          }
        };
      }
      return state;
    case "PRIVATE_COLLECTION_DASHBOARD_TOGGLE_COLLECTION_VISIBILITY":
      if (state?.dashboard) {
        const updatedCollections = state.dashboard.new.collections.map(collection =>
          collection.id === action.data.id
            ? { ...collection, is_visible: !collection.is_visible }
            : collection
        );
        return {
          ...state,
          dashboard: {
            ...state.dashboard,
            new: {
              ...state.dashboard.new,
              collections: updatedCollections
            }
          }
        };
      }
      return state;
    case "PRIVATE_COLLECTION_DASHBOARD_CANCEL_EDIT":
      if (state?.dashboard) {
        return {
          ...state,
          dashboard: {
            ...state.dashboard,
            new: state.dashboard.existing
          }
        };
      }
      return state;
    case updatePrivateCollectionDashboard.fulfilled.type:
      if (state?.dashboard) {
        return {
          ...state,
          dashboard: {
            ...state.dashboard,
            existing: state.dashboard.new
          }
        }
      }
      return state;
    case "LANDING_PAGE_ABOUT_DATA":
      if (state && state.dashboard_tags) {
        return {
          ...state,
          ...action.data[0],
        };
      } else if (action.data.length > 0) {
        return action.data[0];
      }
      return "404";
    case "LOGOUT":
    case "LANDING_PAGE_ABOUT_DATA_RESET":
      return null;
    case "CHANGE_LANDING_PAGE_PROFILE_PIC":
      return {
        ...state,
        source: action.data.source,
        img: action.data.img,
      };
    case "CHANGE_LANDING_PAGE_NAME":
      return {
        ...state,
        name: action.data.name,
      };
    case "CHANGE_LANDING_PAGE_SUMMARY":
      return {
        ...state,
        summary: action.data,
      };
    case "CHANGE_LANDING_PAGE_COLORS":
      return {
        ...state,
        primary_color: action.data.primary_color,
        secondary_color: action.data.secondary_color,
      };
    case "CHANGE_LANDING_PAGE_COVER":
      return Object.assign({}, state, { cover_url: action.data.cover });
    case "COLLECTION_UPLOAD_COVER_DATA": {
      return  {
        ...state,
        cover_url: null,
        background: action.data.background
      };
    }
    case collectionCountryUpdated.type:
      return {
        ...state,
        country_id: action.payload.countryId
      };
    default:
      return state;
  }
};

export const dataLandingPageAnalytics = (state = null, action) => {
  switch (action.type) {
    case "UPDATE_WHITELISTED_EMAILS":
      return {
        ...state,
        whitelisted_emails: action.data,
      };
    case "FACINGS_SHOW_SCATTER_PLOT":
      return {
        ...state,
        facings_show_scatter: true,
      };
    case "USER_SET_PREFERENCE_PRICING_MASS":
    case "FACINGS_SHOW_VISUALS":
      return {
        ...state,
        facings_show_scatter: false,
      };
    case "RESET_ANALYTICS_DATA":
      return {
        ...state,
        analytics: null,
      };
    case "COLLECTION_COVERAGE_MODEL_DATA":
      return {
        ...state,
        coverage_model: action.data,
      };
    case "ANALYTICS_DISCOVERABILITY_RESET_BRANDS":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          discoverability_brands: [],
        },
      };
    case "ANALYTICS_DISCOVERABILITY_ADD_BRAND":
      const currentDiscoverabilityBrands =
        state.analytics.discoverability_brands || [];
      return {
        ...state,
        analytics: {
          ...state.analytics,
          discoverability_brands: currentDiscoverabilityBrands.concat(
            action.data
          ),
        },
      };
    case "ANALYTICS_DISCOVERABILITY_REMOVE_BRAND":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          discoverability_brands: state.analytics.discoverability_brands.filter(
            (x) => x !== action.data
          ),
        },
      };
    case "ANALYTICS_POG_SIZE_OVER_TIME_RESET_RETAILERS":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          pog_over_time_retailers: [],
        },
      };
    case "ANALYTICS_POG_SIZE_OVER_TIME_ADD_RETAILER":
      const currentPogSizeRetailers =
        state.analytics.pog_over_time_retailers || [];
      return {
        ...state,
        analytics: {
          ...state.analytics,
          pog_over_time_retailers: currentPogSizeRetailers.concat(action.data),
        },
      };
    case "ANALYTICS_POG_SIZE_OVER_TIME_REMOVE_RETAILER":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          pog_over_time_retailers:
            state.analytics.pog_over_time_retailers.filter(
              (x) => x !== action.data
            ),
        },
      };
    case "ANALYTICS_REPORT_GET_PLACE_DISTRIBUTION_TIME_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, distribution_time: action.data },
      };
    case "ANALYTICS_REPORT_GET_PROMOTION_OMNICHANNEL_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, omnichannel: action.data },
      };
    case "ANALYTICS_REPORT_GET_PROMOTION_FRONT_PAGE_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, front_page: action.data },
      };
    case "ANALYTICS_REPORT_GET_PROMOTION_DISPLAY_CALENDAR_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, display_calendar: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_BDI_BY_GEOGRAPHY_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, bdi_by_geography: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_BDI_BY_URBANIZATION_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, bdi_by_urbanization: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_BDI_BY_RETAILER_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, bdi_by_retailer: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_BRAND_ASSORTMENT_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, brand_assortment: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_LISTINGS_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, listings: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRICING_DISCOUNTING_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, discounting: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRICING_PRICING_ASSORTMENT_GEOGRAPHY_DATA":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          pricing_assortment_geography: action.data,
        },
      };
    case "ANALYTICS_REPORT_GET_PRICING_PRICING_ASSORTMENT_URBANIZATION_DATA":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          pricing_assortment_urbanization: action.data,
        },
      };
    case "ANALYTICS_REPORT_GET_PRICING_PRICING_ASSORTMENT_RETAILER_DATA":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          pricing_assortment_retailer: action.data,
        },
      };
    case "ANALYTICS_REPORT_GET_PLACE_DISTRIBUTION_GAPS_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, distribution_gaps: action.data },
      };
    case "ANALYTICS_REPORT_GET_PROMOTION_DISPLAY_SHARE_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, display_share: action.data },
      };
    case "ANALYTICS_REPORT_GET_PLACE_CATEGORY_ADJACENCIES_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, category_adjacencies: action.data },
      };
    case "ANALYTICS_REPORT_GET_PLACE_BRAND_ADJACENCIES_DATA":
      if (state.analytics && state.analytics.brand_adjacencies) {
        return {
          ...state,
          analytics: {
            ...state.analytics,
            brand_adjacencies: {
              ...state.analytics.brand_adjacencies,
              ...action.data,
            },
          },
        };
      } else {
        return {
          ...state,
          analytics: {
            ...state.analytics,
            brand_adjacencies: action.data,
          },
        };
      }
    case "ANALYTICS_REPORT_GET_PLACE_DISCOVERABILITY_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, discoverability: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRICING_INFLATION_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, inflation: action.data },
      };
    case "ANALYTICS_REPORT_GET_PLACE_POG_OVER_TIME_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, pog_over_time: action.data },
      };
    case "ANALYTICS_REPORT_GET_PLACE_POG_SIZE_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, pog_size: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_LEADERS_BY_CHANNEL_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, leaders_by_channel: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_LEADERS_BY_GEOGRAPHY_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, leaders_by_geography: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_LEADERS_BY_RETAILER_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, leaders_by_retailer: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_SOCIAL_MEDIA_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, social_media: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRICING_AVERAGE_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, average: action.data },
      };
    case "SET_INCLUDED_CATEGORIES_FOR_ANALYTICS":
      return { ...state, included_categories: action.data };
    case "ANALYTICS_REPORT_INCLUDE":
      if (state.excluded_reports) {
        return {
          ...state,
          excluded_reports: state.excluded_reports.filter(
            (x) => x != action.data
          ),
        };
      }
      return state;
    case "ANALYTICS_REPORT_EXCLUDE":
      if (state.excluded_reports) {
        return {
          ...state,
          excluded_reports: [action.data].concat(state.excluded_reports),
        };
      }
      return { ...state, excluded_reports: [action.data] };
    case "REMOVE_PREAPPROVED_EMAIL_DOMAIN_LOCALLY":
      return {
        ...state,
        whitelisted_emails: state.whitelisted_emails.filter(
          (x) => x.preapproved_id != action.data
        ),
      };
    case "WHITELIST_EMAIL_DOMAIN_DATA":
      return {
        ...state,
        whitelisted_emails: [action.data]
          .concat(state.whitelisted_emails || [])
          .filter(
            (v, i, a) =>
              a.findIndex((t) => t.preapproved_id === v.preapproved_id) === i
          ),
      };
    case "LANDING_PAGE_ANALYTICS_DATA":
      if (action.data.length > 0) {
        return action.data[0];
      }
      return "404";
    case "LANDING_PAGE_USER_DATA":
      return Object.assign({}, state, { user_data: action.data });
    case "LANDING_PAGE_MORE_ANALYTICS_DATA":
      return Object.assign({}, state, { ...action.data[0], loadedMore: true });
    case "LANDING_PAGE_ANALYTICS_DATA_RESET":
      return null;
    case "TOGGLE_USER_PRIVATE_COLLECTION_ACCESS_LOCALLY":
      const currentUsers1 = state.user_data.map((x) => ({
        ...x,
        active: x.id == action.data.userId ? action.data.status : x.active,
      }));
      return Object.assign({}, state, { user_data: currentUsers1 });
    case "REMOVE_USER_FROM_COLLECTION":
      const currentUsers2 = state.user_data.filter((x) => x.id !== action.data);
      return Object.assign({}, state, { user_data: currentUsers2 });
    case "ANALYTICS_REPORT_GET_PLACE_OSA_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, osa: action.data},
      };
    case "ANALYTICS_REPORT_UPDATE_OSA_FOOTER":
      if (state?.analytics?.osa?.data) {
        return {
          ...state,
          analytics: {
            ...state.analytics,
            osa: {
              ...state.analytics.osa,
              footer: action.payload.footer
            }
          }
        }
      }
      return state;
    default:
      return state;
  }
};

export const dataLandingPageLocations = (state = null, action) => {
  switch (action.type) {
    case "LANDING_PAGE_LOCATIONS_DATA":
      return action.data;
    case "LANDING_PAGE_LOCATIONS_DATA_RESET":
      return null;
    default:
      return state;
  }
};

export const dataSearchQuery = (state = {}, action) => {
  switch (action.type) {
    case "SEARCH_QUERY_ALL_DATA":
      return { all: action.data };
    case "SEARCH_QUERY_CIRCULARS_DATA":
      return { ...state, circulars: action.data };
    case "SEARCH_QUERY_PHOTOS_DATA":
      return { ...state, photos: action.data };
    case "SEARCH_QUERY_BRANDS_DATA":
      return { ...state, brands: action.data };
    case "SEARCH_QUERY_RETAILERS_DATA":
      return { ...state, retailers: action.data };
    case "SEARCH_QUERY_CHANNELS_DATA":
      return { ...state, channels: action.data };
    case "SEARCH_QUERY_CATEGORIES_DATA":
      return { ...state, categories: action.data };
    case "SEARCH_QUERY_COLLECTIONS_DATA":
      return { ...state, collections: action.data };
    case "SEARCH_QUERY_STATES_DATA":
      return { ...state, states: action.data };
    case "SEARCH_QUERY_CITIES_DATA":
      return { ...state, cities: action.data };
    case "SEARCH_QUERY_COUNTRIES_DATA":
      return { ...state, countries: action.data };
    case "SEARCH_QUERY_TAGS_DATA":
      return { ...state, tags: action.data };
    case "SEARCH_QUERY_PRODUCTS_DATA":
      return { ...state, products: action.data };
    case "SEARCH_QUERY_DATA_RESET":
    case "HIDE_SEARCH":
      return {};
    default:
      return state;
  }
};

export const dataAddToCollectionPhotoCheck = (state = null, action) => {
  switch (action.type) {
    case "COLLECTION_PHOTO_CHECK_DATA":
      return action.data && action.data.map((x) => x.url_slug);
    case "HIDE_VIEWER":
    case "VIEWER_DATA_SET":
    case "@@router/LOCATION_CHANGE":
    case "LOGOUT":
    case "ADD_TO_COLLECTION_DATA_RESET":
      return null;
    default:
      return state;
  }
};

export const dataSharePage = (state = null, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      if (window.location.pathname.includes("/s/")) {
        return state;
      } else if (window.location.pathname.includes("/f/")) {
        return state;
      }
      return null;
    case "LOGOUT":
      return null;
    case "PHOTO_CHECK_SAVED_DATA":
      return { ...state, saved: action.data.saved };
    case "FLYERS_SIMILAR_ONES_DATA":
      return { ...state, similar_pages: action.data };
    case "FLYERS_GET_IN_STORE_PHOTOS_DATA":
      if (state) {
        return {
          ...state,
          store_photos: action.data,
        };
      }
      return state;
    case "VIEWER_DATA_SET":
      if (state) {
        return action.data;
      }
      return state;
    case "PHOTO_SAVE_UPDATE_LOCALLY":
      if (state?.id === action.data.photo_id) {
        return { ...state, saved: action.data.saved };
      }
      return state;
    case fetchPhotoByImg.fulfilled.type:
    case fetchFlyerByImg.fulfilled.type:
      const data = action.payload;
      if (data.error) {
        return data
      } else {
        return {
          ...state,
          ...data,
          planogram: data.planogram_imgs,
          categories: data.tags?.filter(x => x.type === "categories") ?? [],
          channels: data.tags?.filter(x => x.type === "channels") ?? [],
          collections: data.tags?.filter(x => x.type === "collections") ?? [],
          companies: data.tags?.filter(x => x.type === "companies") ?? [],
          tags: data.tags?.filter(x => x.type === "tags") ?? [],
        };
      }
    case photosLinkedToCollections.type:
      if (state?.id && action.payload.photos.some(photo => photo.id === state.id)) {
        const currentCollectionIds = state.collections?.map(collection => collection.url_slug) || [];
        const newCollectionTags = action.payload.collections
          .filter(linkedCollection => !currentCollectionIds.includes(linkedCollection.id))
          .map(({ img, name, primary_color, id }) => ({
            img, name, primary_color,
            type: "collections",
            url_slug: id
          }));
        return {
          ...state,
          collections: state.collections.concat(newCollectionTags)
        };
      }
      return state;
    case photoUnlinkedFromCollection.type:
      const { photo_id, collection_id } = action.payload;
      if (state?.id === photo_id) {
        return {
          ...state,
          collections: state.collections.filter(x => x.url_slug !== collection_id),
        };
      }
      return state;
    case addTagsToPhoto.fulfilled.type:
    case addTagsToFlyer.fulfilled.type: {
      const { photo_id, ...addedTag } = action.meta.arg.body;
      const { type: tagType } = addedTag;

      if (state?.id === photo_id && state[tagType]) {
        if (!state[tagType].find(tag => tag.url_slug === addedTag.url_slug)) {
          return {
            ...state,
            [tagType]: state[tagType].concat(addedTag)
          }
        }
      }
      return state;
    }
    case removeTagsFromPhoto.fulfilled.type:
    case removeTagsFromFlyer.fulfilled.type: {
      const { photo_id, ...removedTag } = action.meta.arg.body;
      const { type: tagType } = removedTag;

      if (state?.id === photo_id && state[tagType]) {
        return {
          ...state,
          [tagType]: state[tagType].filter(tag => tag.url_slug !== removedTag.url_slug)
        }
      }
      return state;
    }
    default:
      return state;
  }
};

const defaultDataViewerSettings = {
  activeComment: null,
  pogPosition: null,
  showSideBar: true,
  showOsa: false,
  showFullScreen: false,
  showTimeTravel: false,
  showTextAnnotations: false,
  sideBarTab: "details",
  textQuery: "",
};

export const dataViewerSettings = (
  state = defaultDataViewerSettings,
  action
) => {
  switch (action.type) {
    case "HIDE_HEATMAP":
      return {
        ...defaultDataViewerSettings,
        showSideBar: true,
      };
    case "SHOW_HEATMAP":
      return {
        ...defaultDataViewerSettings,
        showSideBar: false,
      };
    case "VIEWER_SHOW_TIME_TRAVEL":
      return {
        ...defaultDataViewerSettings,
        sideBarTab: "time_travel",
        showTimeTravel: true,
        showSideBar: false,
      };
    case addPhotoView.fulfilled.type:
      return {
        ...state,
        viewerOpenAsync: true,
      };
    case "SHOW_OSA":
      return {
        ...defaultDataViewerSettings,
        showOsa: true,
        showSideBar: state.showSideBar,
      };
    case "HIDE_OSA":
      return {
        ...state,
        showOsa: false,
      };
    case "VIEWER_POG_SET_POSITION":
      return {
        ...state,
        pogPosition: action.data,
      };
    case "SHOW_TEXT_ANNOTATIONS":
      return {
        ...defaultDataViewerSettings,
        sideBarTab: "text",
        showSideBar: false,
        textQuery: "",
        showTextAnnotations: true,
      };
    case "VIEWER_HIDE_FULLSCREEN":
      return {
        ...state,
        showFullScreen: false,
      };
    case "VIEWER_SHOW_FULLSCREEN":
      return {
        ...state,
        showFullScreen: true,
      };
    case "VIEWER_SHOW_FLYER":
      return {
        ...defaultDataViewerSettings,
        showSideBar: false,
        sideBarTab: "flyer",
      };
    case "VIEWER_SHOW_MAP":
      return {
        ...defaultDataViewerSettings,
        showSideBar: false,
        sideBarTab: "map",
      };
    case "VIEWER_SHOW_DETAILS":
      return {
        ...defaultDataViewerSettings,
        sideBarTab: "details",
      };
    case "VIEWER_SHOW_STUDIO":
      return {
        ...defaultDataViewerSettings,
        sideBarTab: "studio",
        showSideBar: false,
      };
    case "VIEWER_SHOW_COMMENTS":
      return {
        ...defaultDataViewerSettings,
        sideBarTab: "comments",
        showSideBar: true,
      };
    case "VIEWER_SHOW_COLLECTIONS":
      return {
        ...defaultDataViewerSettings,
        sideBarTab: "collections",
      };
    case "VIEWER_CLOSE_SIDEBAR":
      return {
        ...defaultDataViewerSettings,
        showSideBar: false,
      };
    case "CONFIRM_NEW_REPLY":
    case "OPEN_A_COMMENT":
    case "CONFIRM_NEW_COMMENT":
    case "CANCEL_ACTIVE_COMMENT":
      return { ...state, activeComment: null };
    case "ADD_NEW_COMMENT":
      return { ...state, activeComment: { ...action.data, open: true } };
    case "HIDE_VIEWER":
    case "@@router/LOCATION_CHANGE":
    case "LOGOUT":
    case "SHOW_DRAW":
    case "VIEWER_OPEN_SIDEBAR":
    case "VIEWER_HIDE_TIME_TRAVEL":
      return defaultDataViewerSettings;
    case "VIEWER_TEXT_QUERY":
      return { ...state, viewerTextQuery: action.data };
    case "SHOW_TAGGING":
    case "RESET_VIEWER_TEXT_QUERY":
      return { ...state, viewerTextQuery: null };
    case "SET_TEXT_QUERY_FROM_VIEWER":
      return { ...state, textQuery: action.data };
    case "HIDE_TEXT_ANNOTATIONS":
      return { ...state, showTextAnnotations: false, showSideBar: true };
    case "SET_TEXT_QUERY_IN_VIEWER":
      return { ...state, textQuery: action.data, showTextAnnotations: true };
    default:
      return state;
  }
};

export const dataViewer = (state = null, action) => {
  switch (action.type) {
    case "TIME_TRAVEL_SPECIFIC_PHOTO_DATA":
      return {
        ...state,
        time_travel: action.data,
      };
    case "OSA_ANNOTATIONS_DATA":
      return {
        ...state,
        osa: action.data,
      };
    case "PHOTO_RESPONSE_GROUP_CONTENT_DATA":
      return {
        ...state,
        response_group: action.data && action.data[0],
      };
    case "REVERSE_POG_DIRECTION":
      return {
        ...state,
        planogram: action.data,
      };
    case "QR_CODE_GET_CAMPAIGN_DATA":
      return {
        ...state,
        campaign_photos: action.data.map((x) => x.url_s3),
        campaign_photo_ids: action.data.map((x) => x.id),
      };
    case "PHOTO_GET_QR_CODES_DATA":
      return {
        ...state,
        qr_codes: action.data,
      };
    case "VIEWER_RELATED_FACINGS_DATA":
      return {
        ...state,
        facings: action.data,
      };
    case "FLYER_FROM_PHOTO_DATA":
      return { ...state, flyer_pages: action.data };
    case "PHOTO_CHECK_SAVED_DATA":
      return { ...state, saved: action.data.saved };
    case "FLYERS_SIMILAR_ONES_DATA":
      return { ...state, similar_pages: action.data };
    case "RELATED_PLANOGRAM_DATA":
      return { ...state, planogram: action.data };
    case "CONFIRM_NEW_REPLY":
      return {
        ...state,
        comments: state.comments.map((x, i) =>
          i === action.data.index
            ? { ...x, replies: x.replies.concat(action.data) }
            : x
        ),
      };
    case "CONFIRM_NEW_COMMENT":
      if (state && state.comments) {
        return {
          ...state,
          comments: state.comments.concat({ ...action.data, open: true }),
        };
      } else {
        return { ...state, comments: [{ ...action.data, open: true }] };
      }
    case "DELETE_A_COMMENT":
      return {
        ...state,
        comments: state.comments.filter(
          (x) => x.x !== action.data.x && x.y !== action.data.y
        ),
      };
    case "OPEN_A_COMMENT":
      return {
        ...state,
        comments: state.comments.map((x) => ({
          ...x,
          open: x.x === action.data.x && x.y === action.data.y,
          justCreated: false,
        })),
      };
    case "ADD_NEW_COMMENT":
    case "HIDE_COMMENT_MODE":
    case "CANCEL_ACTIVE_COMMENT":
      if (state && state.comments) {
        return {
          ...state,
          comments: state.comments.map((x) => ({ ...x, open: false })),
        };
      }
      return state;
    case "PHOTO_SAVE_UPDATE_LOCALLY":
      if (state?.id === action.data.photo_id) {
        return {
          ...state,
          saved: action.data.saved,
        };
      }
      return state;
    case "FLYERS_GET_IN_STORE_PHOTOS_DATA":
      return {
        ...state,
        store_photos: action.data,
      };
    case "RELATED_PHOTOS_SAME_DATA":
      return {
        ...state,
        related_photos_same: action.data,
      };
    case "RELATED_PHOTOS_OTHER_DATA":
      return {
        ...state,
        related_photos_other: action.data,
      };
    case "TEXT_ANNOTATIONS_DATA":
      if (state) {
        return {
          ...state,
          text: action.data,
        };
      }
      return { text: action.data };
    case fetchPhotoByImg.fulfilled.type:
    case fetchFlyerByImg.fulfilled.type:
      const data = action.payload;
      if (data.error) {
        return state;
      } else {
        return {
          ...state,
          ...data,
          planogram: data.planogram_imgs,
          categories: data.tags?.filter(x => x.type === "categories") ?? [],
          channels: data.tags?.filter(x => x.type === "channels") ?? [],
          collections: data.tags?.filter(x => x.type === "collections") ?? [],
          companies: data.tags?.filter(x => x.type === "companies") ?? [],
          tags: data.tags?.filter(x => x.type === "tags") ?? [],
        };
      }
    case "VIEWER_DATA":
      if (state) {
        return {
          ...state,
          ...action.data[0],
        };
      }
      return action.data[0];
    case "VIEWER_DATA_SET":
      if (action.data) {
        return action.data;
      }
      return state;
    case "HIDE_VIEWER":
      return null;
    case addTagsToPhoto.pending.type:
    case addTagsToFlyer.pending.type:
    case removeTagsFromPhoto.rejected.type:
    case removeTagsFromFlyer.rejected.type: {
      const { photo_id, ...updatedTag } = action.meta.arg.body;
      const { type: tagType } = updatedTag;

      if (state?.id === photo_id) {
        if (!state[tagType].find(tag => tag.url_slug === updatedTag.url_slug)) {
          return {
            ...state,
            [tagType]: [...state[tagType], updatedTag]
          };
        }
      }
      return state;
    }
    case removeTagsFromPhoto.pending.type:
    case removeTagsFromFlyer.pending.type:
    case addTagsToPhoto.rejected.type:
    case addTagsToFlyer.rejected.type: {
      const { photo_id, ...updatedTag } = action.meta.arg.body;
      const { type: tagType } = updatedTag;

      if (state?.id === photo_id) {
        return {
          ...state,
          [tagType]: state[tagType].filter(tag => tag.url_slug !== updatedTag.url_slug)
        }
      }
      return state;
    }
    case photoUnlinkedFromCollection.type:
      if (state?.id === action.payload.photo_id) {
        return {
          ...state,
          collections: state.collections.filter((x) => x.url_slug !== action.payload.collection_id),
        };
      }
      return state;
    case photosLinkedToCollections.type:
      if (state?.id && action.payload.photos.some(photo => photo.id === state.id)) {
        const currentCollectionIds = state.collections?.map(collection => collection.url_slug) || [];
        const newCollectionTags = action.payload.collections
          .filter(linkedCollection => !currentCollectionIds.includes(linkedCollection.id))
          .map(({ img, name, primary_color, id }) => ({
            img, name, primary_color,
            type: "collections",
            url_slug: id
          }));
        return {
          ...state,
          collections: state.collections.concat(newCollectionTags)
        };
      }
      return state;
    default:
      return state;
  }
};

export const dataSearchByText = (state = null, action) => {
  switch (action.type) {
    case "SEARCH_PHOTOS_BY_TEXT_DATA":
      return action.data;
    case "PHOTO_SEARCH_QUERY_DATA_RESET":
    case "LANDING_PAGE_ABOUT_DATA_RESET":
      return null;
    default:
      return state;
  }
};

export const dataFeed = (state = null, action) => {
  switch (action.type) {
    case prefetchedPhotosApplied.type:
      return { ...action.payload.prefetchedData, last_updated: new Date() };
    case "FEED_PHOTOS_DATA":
      if (state) {
        if (action.data.data.length > 0) {
          const array = clone(state.data).concat(action.data.data);
          const result = [];
          const map = new Map();
          for (const item of array) {
            if (!map.has(item.id)) {
              map.set(item.id, true); // set any value to Map
              result.push(item);
            }
          }
          return {
            ...state,
            data: result,
            meta: action.data.meta,
            page: action.data.page,
            where: action.data.where,
          };
        } else if (action.data.page > 0) {
          return { ...state, meta: action.data.meta, page: action.data.page };
        }
        const uniquePhotos = [];
        const mapPhotos = new Map();
        for (const itemPhotos of action.data.data) {
          if (!mapPhotos.has(itemPhotos.id)) {
            mapPhotos.set(itemPhotos.id, true); // set any value to Map
            uniquePhotos.push(itemPhotos);
          }
        }
        return { ...action.data, data: uniquePhotos };
      } else if (
        action.data &&
        action.data.location == window.location.pathname
      ) {
        const uniquePhotos2 = [];
        const mapPhotos2 = new Map();
        for (const itemPhotos2 of action.data.data) {
          if (!mapPhotos2.has(itemPhotos2.id)) {
            mapPhotos2.set(itemPhotos2.id, true); // set any value to Map
            uniquePhotos2.push(itemPhotos2);
          }
        }
        return { ...action.data, data: uniquePhotos2 };
      }
      return state;
    case photoUnlinkedFromCollection.type:
      if (state?.location?.includes(action.payload.collection_id)) {
        return {
          ...state,
          data: state.data.filter(x => x.id !== action.payload.photo_id)
        };
      }
      return state;
    case photosLinkedToCollections.type:
      if (state?.data) {
        const linkedPhotoIds = action.payload.photos.map(photo => photo.id);
        const collectionTags = action.payload.collections.map(
          ({ img, name, primary_color, id }) => ({
            img, name, primary_color,
            type: "collections",
            url_slug: id,
          })
        );
        // If any of the linked photos are currently in the feed, add the tags to them
        const updatedPhotos = state.data.map(photo => {
          if (!linkedPhotoIds.includes(photo.id)) {
            return photo;
          }
          const currentTags = photo.tags.filter(tag => tag.type === "collections").map(tag => tag.url_slug);
          const newCollectionTags = collectionTags.filter(tag => !currentTags.includes(tag.url_slug));
          return {
            ...photo,
            tags: photo.tags.concat(newCollectionTags)
          };
        });
        // If the feed is for a user collection landing page, add any missing linked photos to it
        if (action.payload.collections.some(collection => state?.location?.includes(collection.id))) {
          const currentPhotoIds = state.data.map(photo => photo.id);
          const newPhotos = action.payload.photos
            .filter(linkedPhoto => !currentPhotoIds.includes(linkedPhoto.id))
            .map(linkedPhoto => ({ ...linkedPhoto, tags: linkedPhoto.tags.concat(collectionTags) }));

          return {
            ...state,
            data: newPhotos.concat(updatedPhotos),
            meta: state.meta.count + newPhotos.length
          };
        } else {
          return {
            ...state,
            data: updatedPhotos
          }
        }
      }
      return state;
    case addTagsToPhoto.fulfilled.type: {
      const { photo_id, ...addedTag } = action.meta.arg.body;
      if (state?.data) {
        const updatedPhotos = state.data.map(photo => photo.id === photo_id
          ? { ...photo, tags: photo.tags.concat(addedTag) }
          : photo
        );
        return {
          ...state,
          data: updatedPhotos
        };
      }
      return state;
    }
    case removeTagsFromPhoto.fulfilled.type: {
      const { photo_id, ...removedTag } = action.meta.arg.body;
      if (state?.data) {
        const updatedPhotos = state.data.map(photo => photo.id === photo_id
          ? { ...photo, tags: photo.tags.filter(tag => tag.url_slug !== removedTag.url_slug) }
          : photo
        );
        return {
          ...state,
          data: updatedPhotos
        };
      }
      return state;
    }
    case "PHOTO_SAVE_UPDATE_LOCALLY":
      const { photo_id, saved } = action.data;
      if (state?.data) {
        const updatedPhotos = state.data.map(photo => photo.id === photo_id
          ? { ...photo, saved }
          : photo
        );
        return {
          ...state,
          data: updatedPhotos
        };
      }
      return state;
    case "FEED_PHOTOS_DATA_RESET":
      return null;
    default:
      return state;
  }
};

export const lastVisitUser = (state = "settings", action) => {
  switch (action.type) {
    case "SET_SETTINGS":
      return "settings";
    case "SET_SHARED_PHOTOS":
      return "shared_photos";
    case "SET_MY_PHOTOS":
      return "my_photos";
    case "SET_UPLOAD_PHOTOS":
      return "upload_photos";
    default:
      return state;
  }
};

export const lastVisitDirectory = (state = "channels", action) => {
  switch (action.type) {
    case "SET_LAST_VISIT_DIRECTORY_CATEGORIES":
      return "categories";
    case "SET_LAST_VISIT_DIRECTORY_CHANNELS":
      return "channels";
    case "SET_LAST_VISIT_DIRECTORY_COUNTRIES":
      return "countries";
    case "SET_LAST_VISIT_DIRECTORY_TAGS":
      return "tags";
    case "SET_LAST_VISIT_DIRECTORY_BRANDS":
      return "brands";
    case "SET_LAST_VISIT_DIRECTORY_RETAILERS":
      return "retailers";
    case "SET_LAST_VISIT_DIRECTORY_REGIONS":
      return "regions";
    default:
      return state;
  }
};

export const uploadLocation = (state = false, action) => {
  switch (action.type) {
    case "SET_LOCATION_ID":
      return { id: action.id, locationName: action.locationName };
    case "RESET_LOCATION_ID":
      return false;
    default:
      return state;
  }
};

export const reportedList = (state = [], action) => {
  switch (action.type) {
    case "LOGOUT":
      return [];
    case "ADD_TO_REPORTED":
      if (state && state.length > 0) {
        return action.data.concat(state);
      }
      return action.data;
    default:
      return state;
  }
};

export const unsavedList = (state = [], action) => {
  switch (action.type) {
    case "ADD_TO_UNSAVED":
      return state.concat(action.data.id);
    case "RESET_UNSAVED":
      return [];
    default:
      return state;
  }
};

export const showUpload = (state = false, action) => {
  switch (action.type) {
    case "OPEN_UPLOAD":
      return true;
    case "CLOSE_UPLOAD":
      return false;
    default:
      return state;
  }
};

export const showSearch = (state = false, action) => {
  switch (action.type) {
    case "SHOW_SEARCH":
      return true;
    case "@@router/LOCATION_CHANGE":
    case "MAIN_MENU_SHOW_PC_DASHBOARD":
    case "SHOW_SIDE_MENU":
    case "HIDE_SEARCH":
      return false;
    default:
      return state;
  }
};

export const showHeatmap = (state = false, action) => {
  switch (action.type) {
    case "SHOW_HEATMAP":
      return true;
    case "SHOW_OSA":
    case "VIEWER_SHOW_FULLSCREEN":
    case "VIEWER_SHOW_TIME_TRAVEL":
    case "SHOW_TEXT_ANNOTATIONS":
    case "VIEWER_SHOW_MAP":
    case "HIDE_VIEWER":
    case "HIDE_HEATMAP":
      return false;
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showDraw = (state = false, action) => {
  switch (action.type) {
    case "SHOW_DRAW":
      return true;
    case "VIEWER_DATA_SET":
    case "HIDE_VIEWER":
    case "HIDE_DRAW":
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showViewer = (state = false, action) => {
  switch (action.type) {
    case "CHANGE_VIEWER_TYPE":
      return { ...state, type: action.data };
    case "VIEWER_DATA_SET":
      return { ...state, show_date: new Date() };
    case "SHOW_VIEWER":
      return action.data
        ? typeof variable === "string"
          ? { type: action.data, show_date: new Date() }
          : { ...action.data, show_date: new Date() }
        : true;
    case "HIDE_VIEWER":
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showViewerPog = (state = false, action) => {
  switch (action.type) {
    case "SHOW_PLANOGRAM_EXPANDED_VIEWER":
      return {
        ...state,
        carousel: action.data
          ? { ...action.data, show_date: new Date() }
          : true,
      };
    case "SHOW_PLANOGRAM_HISTORICAL_VIEWER":
      return {
        ...state,
        historical: action.data
          ? { ...action.data, show_date: new Date() }
          : true,
      };
    case "GET_HISTORICAL_PLANOGRAMS_DATA":
      if (state) {
        return {
          ...state,
          historical: { ...state.historical, historical: action.data },
        };
      }
      return state;
    case "HIDE_VIEWER_PLANOGRAM_CAROUSEL":
      return { ...state, carousel: false };
    case "HIDE_VIEWER_PLANOGRAM_HISTORICAL":
      return { ...state, historical: false };
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showFullscreenModal = (state = false, action) => {
  switch (action.type) {
    case "MAP_FILTER_LOCATIONS_DATA":
      return { ...state, pois: action.data };
    case "MAP_FILTER_SET_OUTSIDE":
      return {
        ...state,
        is: false,
      };
    case "MAP_FILTER_SET_INSIDE":
      return {
        ...state,
        is: true,
      };
    case "SHOW_FULLSCREEN_MODAL":
      return { ...action.data } || true;
    case feedFilterConditionRemoved.type:
    case "HIDE_FULLSCREEN_MODAL":
    case feedFilterMapUpdated.type:
    case "@@router/LOCATION_CHANGE":
    case "LOGOUT":
      return false;
    default:
      return state;
  }
};

export const checkEnterpriseTld = (state = null, action) => {
  switch (action.type) {
    case "LOGOUT":
    case "@@router/LOCATION_CHANGE":
    case "RESET_CHECK_ENTERPRISE_TLD":
      return null;
    case "CHECK_SSO_TLD_DATA":
      if (Array.isArray(action.data)) {
        return action.data[0];
      }
      return null;
    case "CHECK_SSO_TLD_AND_GET_EMAIL_CODE_DATA":
      if (Array.isArray(action.data)) {
        return { checked: true, validated: action.data[0] };
      } else if (action.data.free_email_tld) {
        return { checked: true, validated: false, free_email_tld: true };
      } else if (action.data.deactivated) {
        return { checked: true, validated: false, deactivated: true };
      } else if (action.data.redirect) {
        return { redirect: action.data.redirect };
      }
      return {
        ...action.data,
        checked: true,
        validated: false,
        deactivated: false,
      };
    default:
      return state;
  }
};

// To Be Deprecated
export const feedSort = (state = "random", action) => {
  switch (action.type) {
    case "RANDOM":
      return "random";
    case "POPULAR":
      return "popular";
    case "OLDEST":
      return "oldest";
    case "NEWEST":
      return "newest";
    default:
      return state;
  }
};

// To Be Deprecated
export const feedView = (state = "viewAsTiles", action) => {
  switch (action.type) {
    case "CARDS":
      return "viewAsCards";
    case "PHOTOS":
      return "viewAsPhotos";
    case "SLIDESHOW":
      return "viewAsDashboard";
    case "TILES":
      return "viewAsTiles";
    default:
      return state;
  }
};

// To Be Deprecated
export const advertiseSignUpStatus = (state = {}, action) => {
  switch (action.type) {
    case "ACTIVE":
      return true;
    default:
      return false;
  }
};

// To be deprecated
export const showFeedSortMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_FEED_SORT_MENU":
      return true;
    case "HIDE_FEED_DROPDOWN_MENUS":
    case "SHOW_FEED_NAV_MENU":
    case "MAIN_MENU_SHOW_USER":
    case "MAIN_MENU_SHOW_HELP":
    case "MAIN_MENU_SHOW_NOTIFICATIONS":
    case "MAIN_MENU_SHOW_DIRECTORY":
    case "SHOW_FEED_TYPE_MENU":
    case showModal.type:
    case "SHOW_FEED_COMPANY_TYPE_MENU":
    case "MAIN_MENU_HIDE":
    case "@@router/LOCATION_CHANGE":
    case "SHOW_FEED_MORE_MENU":
      return false;
    default:
      return state;
  }
};

export const showFeedCompanyTypeMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_FEED_COMPANY_TYPE_MENU":
      return true;
    case "HIDE_FEED_DROPDOWN_MENUS":
    case "SHOW_FEED_NAV_MENU":
    case "MAIN_MENU_SHOW_USER":
    case "SHOW_FEED_MORE_MENU":
    case showModal.type:
    case "MAIN_MENU_HIDE":
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showFlyerFilterMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_FLYER_FILTER_MENU":
      return true;
    case "HIDE_FEED_DROPDOWN_MENUS":
    case "MAIN_MENU_SHOW_USER":
    case "HIDE_FLYER_FILTER_MENU":
    case "SHOW_FLYER_DATE_PICKER":
    case "SHOW_FEED_NAV_MENU":
    case "HIDE_FLYER_DROPDOWN_MENUS":
    case "MAIN_MENU_HIDE":
    case "SHOW_FEED_SORT_MENU":
    case showModal.type:
    case "SHOW_FEED_MORE_MENU":
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showFeedTypeMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_FEED_TYPE_MENU":
      return true;
    case "HIDE_FEED_DROPDOWN_MENUS":
    case "MAIN_MENU_SHOW_USER":
    case "SHOW_FEED_NAV_MENU":
    case "MAIN_MENU_HIDE":
    case "SHOW_FEED_SORT_MENU":
    case showModal.type:
    case "SHOW_FEED_MORE_MENU":
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showFeedNavMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_FEED_NAV_MENU":
      return true;
    case "SHOW_FEED_COMPANY_TYPE_MENU":
    case "SHOW_FEED_TYPE_MENU":
    case "SHOW_PLANOGRAM_MORE_MENU":
    case "MAIN_MENU_HIDE":
    case "MAIN_MENU_SHOW_HELP":
    case "MAIN_MENU_SHOW_NOTIFICATIONS":
    case "MAIN_MENU_SHOW_USER":
    case "USER_SET_PREFERENCE_PRICING_MASS":
    case "USER_SET_PREFERENCE_PRICING_CLUB":
    case "SHOW_FEED_SORT_MENU":
    case "SHOW_FEED_MORE_MENU":
    case "@@router/LOCATION_CHANGE":
    case showModal.type:
    case "SHOW_FEED_SHIFT_MULTIPLE_SELECT":
    case "LOCATIONS_MAP_ZOOM_IN":
    case "LOCATIONS_MAP_ZOOM_OUT":
    case "HIDE_FEED_DROPDOWN_MENUS":
      return false;
    default:
      return state;
  }
};

// THIS CAN BE DEPRECATED
export const showFeedShareMenu = (state = false, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
    case "HIDE_FEED_DROPDOWN_MENUS":
    case "HIDE_FEED_SHIFT_MULTIPLE_SELECT":
    case "MAIN_MENU_HIDE":
    case "MAIN_MENU_SHOW_USER":
    case "SHOW_FEED_MORE_MENU":
    case "SHOW_FEED_NAV_MENU":
    case "SHOW_FEED_SHIFT_MULTIPLE_SELECT":
    case "SHOW_FEED_SORT_MENU":
    case "SHOW_FEED_TYPE_MENU":
    case showModal.type:
    case "SHOW_PLANOGRAM_MORE_MENU":
      return false;
    default:
      return state;
  }
};

export const showFeedMoreMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_FEED_MORE_MENU":
      return true;
    case "SHOW_FEED_COMPANY_TYPE_MENU":
    case "MAIN_MENU_HIDE":
    case "SHOW_FEED_SORT_MENU":
    case "SHOW_FEED_TYPE_MENU":
    case "SHOW_FEED_NAV_MENU":
    case "MAIN_MENU_SHOW_USER":
    case showModal.type:
    case "@@router/LOCATION_CHANGE":
    case "HIDE_FEED_DROPDOWN_MENUS":
      return false;
    default:
      return state;
  }
};

export const showRVIDateMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_RVI_DATE_MENU":
      return true;
    case "MAIN_MENU_HIDE":
    case "HIDE_RVI_DATE_MENU":
    case "SHOW_RVI_BRAND_MENU":
    case showModal.type:
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showRVIBrandMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_RVI_BRAND_MENU":
      return true;
    case "MAIN_MENU_HIDE":
    case "HIDE_RVI_BRAND_MENU":
    case "SHOW_RVI_DATE_MENU":
    case showModal.type:
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showPlanogramMoreMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_PLANOGRAM_MORE_MENU":
      return true;
    case showModal.type:
    case "SHOW_SEARCH":
    case "HIDE_FEED_DROPDOWN_MENUS":
    case "SHOW_FEED_NAV_MENU":
    case "SHOW_SIDE_MENU":
    case "MAIN_MENU_SHOW_HELP":
    case "MAIN_MENU_SHOW_NOTIFICATIONS":
    case "MAIN_MENU_SHOW_USER":
    case "SHOW_FEED_SHIFT_MULTIPLE_SELECT":
    case "HIDE_PLANOGRAM_MORE_MENU":
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const newProductUpdates = (state = null, action) => {
  switch (action.type) {
    case "CHECK_FOR_NEW_PRODUCT_UPDATES_DATA":
      return {
        lastChecked: new Date(),
        data: action.data,
      };
    case "RESET_CHECK_FOR_NEW_PRODUCT_UPDATES":
      return null;
    default:
      return state;
  }
};

export const dataTagger = (state = null, action) => {
  switch (action.type) {
    case "GET_PHOTO_FOR_TAGGER_DATA":
      return action.data;
    case "ADD_LOGO_FROM_TAGGER_DATA":
    case "REJECT_TAG_FROM_TAGGER_DATA":
    case "ADD_TAG_FROM_TAGGER_DATA":
    case "ADD_CATEGORIES_TO_COMPANY_FROM_TAGGER_DATA":
    case "OPT_OUT_OF_TAGGER_DATA":
    case "SKIP_TAGGER_REQUEST_DATA":
    case "ADD_CHANNELS_TO_COMPANY_FROM_TAGGER_DATA":
      return null;
    default:
      return state;
  }
};

export const dataFeedUntagged = (state = null, action) => {
  switch (action.type) {
    case "GET_PHOTOS_FOR_UNTAGGED":
      return null;
    case "GET_PHOTOS_FOR_UNTAGGED_DATA":
      return action.data;
    default:
      return state;
  }
};

const defaultShowMenuDropdown = {
  user: false,
  pcDashboard: false,
  help: false,
  notifications: false,
};

export const showMenuDropdown = (state = defaultShowMenuDropdown, action) => {
  switch (action.type) {
    case "MAIN_MENU_SHOW_USER":
      return {
        ...defaultShowMenuDropdown,
        user: true,
      };
    case "MAIN_MENU_SHOW_NOTIFICATIONS":
      return {
        ...defaultShowMenuDropdown,
        notifications: true,
      };
    case "MAIN_MENU_SHOW_HELP":
      return {
        ...defaultShowMenuDropdown,
        help: true,
      };
    case "MAIN_MENU_SHOW_PC_DASHBOARD":
      return {
        ...defaultShowMenuDropdown,
        pcDashboard: true,
      };
    case showModal.type:
      return {
        ...defaultShowMenuDropdown,
        pcDashboard: state.pcDashboard,
      };
    case "HIDE_SIDE_MENU":
    case "SHOW_SIDE_MENU":
    case "MAIN_MENU_HIDE":
    case "CLEAR_ENTERPRISE_LOGO_IN_MENU":
    case "CAMPAIGNS_SEARCH_FOCUS":
    case "LOGOUT":
    case "SHOW_SEARCH":
    case "@@router/LOCATION_CHANGE":
      return defaultShowMenuDropdown;
    default:
      return state;
  }
};

export const showMainMenuDropdown = (state = false, action) => {
  switch (action.type) {
    case "SHOW_SIDE_MENU":
    case "MAIN_MENU_HIDE":
    case "CAMPAIGNS_SEARCH_FOCUS":
    case "LOGOUT":
    case showModal.type:
    case "@@router/LOCATION_CHANGE":
      return false;
    case "MAIN_MENU_SHOW_USER":
      return "user";
    case "MAIN_MENU_SHOW_FEED":
      return "feed";
    default:
      return state;
  }
};

export const dataLandingPageRVI = (state = false, action) => {
  switch (action.type) {
    case "OSA_ANNOTATIONS_PLANOGRAM_DATA":
      return {
        ...state,
        planogram_osa: {
          ...state.planogram_osa,
          [action.data.key]: action.data.data,
        },
      };
    case "PLANOGRAM_FILTERS_AVAILABE_VISIT_COUNT_DATA":
      return { ...state, available_visit_counts: action.data };
    case "PLANOGRAM_FILTERS_AVAILABE_SIZES_DATA":
      return { ...state, available_sizes: action.data };
    case "PLANOGRAM_FILTERS_AVAILABE_BRANDS_DATA":
      return { ...state, available_brands: action.data };
    case "PLANOGRAM_FILTERS_AVAILABE_STATES_DATA":
      if (state) {
        return { ...state, available_states: action.data };
      }
      return { available_states: action.data };
    case "ANALYTICS_UNMOUNT":
      return { ...state, planogram_checks: null };
    case "ANALYTICS_PLANOGRAM_CHECK_DATA":
      if (state) {
        return {
          ...state,
          planogram_checks: { data: action.data, last_updated: new Date() },
        };
      }
      return {
        planogram_checks: { data: action.data, last_updated: new Date() },
      };
    case "RVI_ANALYTICS_DATA":
      if (state) {
        return { ...state, ...action.data };
      }
      return action.data;
    case "LOGOUT":
    case "LANDING_PAGE_ABOUT_DATA_RESET":
      return false;
    case "PRESENCE_SELECT_WEEK_DATA":
      return { ...state, presence: action.data };
    default:
      return state;
  }
};

export const dataEmbed = (state = null, action) => {
  switch (action.type) {
    case "FEED_PHOTOS_EMBED_DATA":
      return action.data;
    default:
      return state;
  }
};

export const dataLandingPageRVISettings = (
  state = defaultRviSettings,
  action
) => {
  switch (action.type) {
    case "LOGOUT":
      return defaultRviSettings;
    case hideModal.type:
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.existing,
          },
        },
      };
    case "PLANOGRAM_FILTER_CHANGE_VISIT_COUNT":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            visit_count: action.data,
          },
        },
      };
    case "PLANOGRAM_FILTER_CHANGE_SIZES":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            sizes: action.data,
          },
        },
      };
    case "PLANOGRAM_FILTERS_CHANGE_SORT":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          sort: action.data,
          filtersLastUpdated: new Date(),
        },
      };
    case "PLANOGRAM_FILTERS_SET_DATE_END":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            endDate: action.data,
          },
        },
      };
    case "PLANOGRAM_FILTERS_SET_DATE_START":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            startDate: action.data,
          },
        },
      };
    case "PLANOGRAM_FILTERS_HIDE_DATE_PICKER":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          showDatePicker: false,
        },
      };
    case "PLANOGRAM_FILTERS_SHOW_DATE_PICKER":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          showDatePicker: action.data,
        },
      };
    case "APPLY_PLANOGRAM_FILTERS": {
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          existing: action.data,
          new: action.data,
          filtersLastUpdated: new Date(),
        },
      };
    }
    case "OPEN_POG_SUBFILTER": {
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          expanded_filter: action.data,
        },
      };
    }
    case "CLOSE_ALL_POG_SUBFILTERS": {
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          expanded_filter: null,
        },
      };
    }
    case "ANALYTICS_PLANOGRAM_COLLECTION":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            collections: action.data,
          },
        },
      };
    case "ANALYTICS_PLANOGRAM_RETAILER":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            retailers: action.data,
          },
        },
      };
    case "ANALYTICS_PLANOGRAM_BRAND":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            brands: action.data,
          },
        },
      };
    case "ANALYTICS_PLANOGRAM_STATE":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            states: action.data,
          },
        },
      };
    case "ANALYTICS_PLANOGRAM_OSA":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            osa: action.data,
          },
        },
      };
    case "ANALYTICS_UNMOUNT":
      return { ...defaultRviSettings, selected: state.selected };
    case "ANALYTICS_SET_CHART":
      return { ...state, selected: action.data };
    case "ANALYTICS_SET_NATIONAL_VISIBILITY_BRAND_INTERACTION":
      return {
        ...state,
        national_visibility: {
          ...state.national_visibility,
          toggle: "brand_interaction",
        },
      };
    case "ANALYTICS_SET_NATIONAL_VISIBILITY_OVERALL":
      return {
        ...state,
        national_visibility: {
          ...state.national_visibility,
          toggle: "overall",
        },
      };
    case "ANALYTICS_SET_RETAILER_PIE":
      return {
        ...state,
        leaders_by_retailer: {
          ...state.leaders_by_retailer,
          toggle: "pie",
        },
      };
    case "ANALYTICS_SET_RETAILER_TABLE":
      return {
        ...state,
        leaders_by_retailer: {
          ...state.leaders_by_retailer,
          toggle: "table",
        },
      };
    case "ANALYTICS_SET_GEOGRAPHY_URBANIZATION":
      return {
        ...state,
        leaders_by_geography: {
          ...state.leaders_by_geography,
          toggle: "urbanization",
        },
      };
    case "ANALYTICS_SET_GEOGRAPHY_STATE":
      return {
        ...state,
        leaders_by_geography: {
          ...state.leaders_by_geography,
          toggle: "state",
        },
      };
    case "ANALYTICS_SET_PRESENCE_WEEK":
      return {
        ...state,
        presence: { ...state.presence, week: action.data.id },
      };
    case "ANALYTICS_ANOMALY_DETECTION_WEEK":
      return {
        ...state,
        anomaly_detection: { ...state.anomaly_detection, week: action.data.id },
      };
    case "ANALYTICS_OVERALL_FILTER":
      return {
        ...state,
        national_visibility: {
          ...state.national_visibility,
          filters: action.data,
        },
      };
    case "ANALYTICS_BRAND_SUPPORT_BY_RETAILER_INCLUDE_RETAILERS":
      const currentExcludedRetailers =
        state.brand_support_by_retailer.excludedRetailers;
      if (currentExcludedRetailers.includes(action.data.id)) {
        return {
          ...state,
          brand_support_by_retailer: {
            ...state.brand_support_by_retailer,
            excludedRetailers: currentExcludedRetailers.filter(
              (x) => x !== action.data.id
            ),
          },
        };
      } else {
        currentExcludedRetailers.push(action.data.id);
      }
      return {
        ...state,
        brand_support_by_retailer: {
          ...state.brand_support_by_retailer,
          excludedRetailers: currentExcludedRetailers,
        },
      };
    case "ANALYTICS_DISCOVERABILITY_RETAILER":
      return {
        ...state,
        discoverability: {
          ...state.discoverability,
          retailer: action.data,
        },
      };
    case "ANALYTICS_BRAND_INTERACTION_COLUMNS_TO_SHOW":
      return {
        ...state,
        brand_interaction: {
          ...state.brand_interaction,
          columnsToShow: action.data,
        },
      };
    case "ANALYTICS_LEADER_BY_URBANIZATION_COLUMNS_TO_SHOW":
      return {
        ...state,
        leaders_by_urbanization: {
          ...state.leaders_by_urbanization,
          columnsToShow: action.data,
        },
      };
    case "ANALYTICS_LEADER_BY_GEOGRAPHY_COLUMNS_TO_SHOW":
      return {
        ...state,
        leaders_by_geography: {
          ...state.leaders_by_geography,
          columnsToShow: action.data,
        },
      };
    case "ANALYTICS_LEADER_BY_RETAILER_COLUMNS_TO_SHOW":
      return {
        ...state,
        leaders_by_retailer: {
          ...state.leaders_by_retailer,
          columnsToShow: action.data,
        },
      };
    case "ANALYTICS_LOOK_OF_SUCCESS_BRAND":
      return {
        ...state,
        look_of_success: {
          ...state.look_of_success,
          selectedOption: action.data,
        },
      };
    case "ANALYTICS_SET_PRESENCE_MAIN_SECTION":
      return {
        ...state,
        presence: { ...state.presence, toggle: "main_section" },
      };
    case "ANALYTICS_SET_PRESENCE_DISPLAY":
      return { ...state, presence: { ...state.presence, toggle: "display" } };
    case "ANALYTICS_SET_CATEGORY_AVAILABILITY_DISPLAY":
      return {
        ...state,
        category_availability: {
          ...state.category_availability,
          toggle: "display",
        },
      };
    case "ANALYTICS_SET_CATEGORY_AVAILABILITY_MAIN_SECTION":
      return {
        ...state,
        category_availability: {
          ...state.category_availability,
          toggle: "main_section",
        },
      };
    case "ANALYTICS_SET_LOOK_OF_SUCCESS_MAIN_SECTION":
      return {
        ...state,
        look_of_success: {
          ...state.look_of_success,
          toggle: "main_section",
        },
      };
    case "ANALYTICS_SET_LOOK_OF_SUCCESS_DISPLAY":
      return {
        ...state,
        look_of_success: {
          ...state.look_of_success,
          toggle: "display",
        },
      };
    default:
      return state;
  }
};

export const cacheBreakers = (
  state = {
    feed: null,
  },
  action
) => {
  switch (action.type) {
    case "ADD_FEED_CACHE_BREAKING_ACTION":
      return {
        ...state,
        feed: {
          time: Date.now(),
          description: action.data,
        },
      };
    default:
      return state;
  }
};

export const feedCurrentlyFetching = (state = false, action) => {
  switch (action.type) {
    case "API_CALL_TAKE_LATEST":
      return true;
    case "APPLY_PREFETCHED_FLYERS":
    case prefetchedPhotosApplied.type:
    case "FEED_PHOTOS_DATA":
      return false;
    default:
      return state;
  }
};

export const dateOfLastLogin = (state = null, action) => {
  switch (action.type) {
    case "USER_AUTH_VIA_SOCIAL_SIGN_ON_DATA":
    case "USER_LOGS_IN_DATA":
      return Date.now();
    case "LOGOUT":
      return null;
    default:
      return state;
  }
};

const defaultSuggestions = {
  channels: null,
  categories: null,
  brands: null,
  retailers: null,
  tags: null,
  countries: null,
  collections: null,
  states: null,
};

export const fixPreference = (state = "audits", action) => {
  switch (action.type) {
    case "FIX_OPTIONS_SET_AUDIT":
      return "audits";
    case "FIX_OPTIONS_SET_BROKER":
      return "broker";
    default:
      return state;
  }
};

export const dataFlyers = (state = null, action) => {
  switch (action.type) {
    case "APPLY_PREFETCHED_FLYERS":
      return { ...action.data, last_updated: new Date() };
    case "FEED_FLYERS_DATA":
      if (state) {
        if (action.data.data.length > 0) {
          const array = clone(state.data).concat(action.data.data);
          const result = [];
          const map = new Map();
          for (const item of array) {
            if (!map.has(item.id)) {
              map.set(item.id, true); // set any value to Map
              result.push(item);
            }
          }
          return {
            data: result,
            meta: action.data.meta,
            page: action.data.page,
            where: action.data.where,
          };
        } else if (action.data.page > 0) {
          return { ...state, meta: action.data.meta, page: action.data.page };
        }
        const uniquePhotos = [];
        const mapPhotos = new Map();
        for (const itemPhotos of action.data.data) {
          if (!mapPhotos.has(itemPhotos.id)) {
            mapPhotos.set(itemPhotos.id, true); // set any value to Map
            uniquePhotos.push(itemPhotos);
          }
        }
        return { ...action.data, data: uniquePhotos };
      } else if (action.data) {
        const uniquePhotos2 = [];
        const mapPhotos2 = new Map();
        for (const itemPhotos2 of action.data.data) {
          if (!mapPhotos2.has(itemPhotos2.id)) {
            mapPhotos2.set(itemPhotos2.id, true); // set any value to Map
            uniquePhotos2.push(itemPhotos2);
          }
        }
        return { ...action.data, data: uniquePhotos2 };
      }
      return state;
    case addTagsToFlyer.fulfilled.type: {
      const { photo_id, ...addedTag } = action.meta.arg.body;
      if (state?.data) {
        const updatedFlyers = state.data.map(flyer => flyer.id === photo_id
          ? { ...flyer, tags: flyer.tags.concat(addedTag) }
          : flyer
        );
        return {
          ...state,
          data: updatedFlyers
        }
      }
      return state;
    }
    case removeTagsFromFlyer.fulfilled.type: {
      const { photo_id, ...removedTag } = action.meta.arg.body;
      if (state?.data) {
        const updatedFlyers = state.data.map(flyer => flyer.id === photo_id
          ? { ...flyer, tags: flyer.tags.filter(tag => tag.url_slug !== removedTag.url_slug) }
          : flyer
        );
        return {
          ...state,
          data: updatedFlyers
        };
      }
      return state;
    }
    case "FEED_FLYERS_DATA_RESET":
    case "LOGOUT":
      return null;
    default:
      return state;
  }
};

const defaultDataFlyersSettings = {
  new: {
    retailers_selected: [],
    categories_selected: [],
    countries_selected: [],
    brands_selected: [],
    text_selected: null,
    front_page_only: false,
    dates_selected: null,
  },
  existing: {
    retailers_selected: [],
    categories_selected: [],
    countries_selected: [],
    brands_selected: [],
    text_selected: null,
    front_page_only: false,
    dates_selected: null,
  },
  showDatePicker: false,
  sort: "newest",
};

export const dataFlyersSettings = (
  state = defaultDataFlyersSettings,
  action
) => {
  switch (action.type) {
    case "APPLY_PREFETCHED_FLYERS":
      return {
        ...state,
        prefetchedData: null,
      };
    case "FEED_FLYERS_FILTERS_RESET":
      return {
        ...defaultDataFlyersSettings,
        retailer_options: state.retailer_options,
        retailer_options_last_updated: state.retailer_options_last_updated,
      };
    case "INIT_DEFAULT_ADJACENT_CATEGORY":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          adjacencies: action.data,
        },
      };
    case "FLYERS_SET_ADJACENT_CATEGORY":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          adjacencies: action.data,
        },
      };
    case "FLYER_SETTINGS_CUSTOM":
      return {
        ...state,
        new: {
          ...state.new,
          ...action.data,
        },
        existing: {
          ...state.existing,
          ...action.data,
        },
      };
    case "FLYER_SETTINGS_LP_CATEGORY":
      return {
        ...state,
        new: {
          ...state.new,
          front_page_only: false,
          categories_selected: action.data,
          countries_selected: [],
          products_selected: [],
          brands_selected: [],
          retailers_selected: [],
        },
        existing: {
          ...state.existing,
          front_page_only: false,
          categories_selected: action.data,
          countries_selected: [],
          products_selected: [],
          brands_selected: [],
          retailers_selected: [],
        },
      };
    case "FLYER_SETTINGS_LP_RETAILER":
      return {
        ...state,
        new: {
          ...state.new,
          front_page_only: false,
          brands_selected: [],
          products_selected: [],
          categories_selected: [],
          countries_selected: [],
          retailers_selected: action.data,
        },
        existing: {
          ...state.existing,
          front_page_only: false,
          brands_selected: [],
          products_selected: [],
          categories_selected: [],
          countries_selected: [],
          retailers_selected: action.data,
        },
      };
    case "FLYER_SETTINGS_LP_BRAND":
      return {
        ...state,
        new: {
          ...state.new,
          front_page_only: false,
          retailers_selected: [],
          categories_selected: [],
          countries_selected: [],
          products_selected: [],
          brands_selected: action.data,
        },
        existing: {
          ...state.existing,
          front_page_only: false,
          retailers_selected: [],
          categories_selected: [],
          countries_selected: [],
          products_selected: [],
          brands_selected: action.data,
        },
      };
    case "FLYER_SETTINGS_LP_PRODUCT":
      return {
        ...state,
        new: {
          ...state.new,
          front_page_only: false,
          retailers_selected: [],
          categories_selected: [],
          countries_selected: [],
          brands_selected: [],
          products_selected: action.data,
        },
        existing: {
          ...state.existing,
          front_page_only: false,
          retailers_selected: [],
          categories_selected: [],
          countries_selected: [],
          brands_selected: [],
          products_selected: action.data,
        },
      };
    case "FLYER_SETTINGS_LP_COUNTRY":
      return {
        ...state,
        new: {
          ...state.new,
          front_page_only: false,
          retailers_selected: [],
          categories_selected: [],
          products_selected: [],
          brands_selected: [],
          countries_selected: action.data,
        },
        existing: {
          ...state.existing,
          front_page_only: false,
          retailers_selected: [],
          categories_selected: [],
          products_selected: [],
          brands_selected: [],
          countries_selected: action.data,
        },
      };
    case "SET_FLYER_RULES_COUNTRIES":
      return {
        ...state,
        new: {
          ...state.new,
          country: action.data.id,
        },
      };
    case "SET_FLYER_RULES_RETAILERS":
      return {
        ...state,
        new: {
          ...state.new,
          retailers_selected: action.data,
        },
      };
    case "SET_FLYER_RULES_CATEGORIES":
      return {
        ...state,
        new: {
          ...state.new,
          categories_selected: action.data,
        },
      };
    case "SET_FLYER_RULES_BRANDS":
      return {
        ...state,
        new: {
          ...state.new,
          brands_selected: action.data,
        },
      };
    case "FLYER_FILTERS_PAGE_ANY":
      return {
        ...state,
        new: {
          ...state.new,
          front_page_only: false,
        },
      };
    case "FLYER_FILTERS_PAGE_FRONT":
      return {
        ...state,
        new: {
          ...state.new,
          front_page_only: true,
        },
      };
    case "FLYER_ANALYTICS_TIMELINE_CHART":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          timeline: { chart: true },
        },
      };
    case "FLYER_ANALYTICS_TIMELINE_TABLE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          timeline: { chart: false },
        },
      };
    case "FLYER_ANALYTICS_CATEGORY_SHARE_FRONT_PAGE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          category_share: { front_page_only: true },
        },
      };
    case "FLYER_ANALYTICS_PRESENCE_SHARE_FRONT_PAGE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          presence: { front_page_only: true },
        },
      };
    case "FLYER_ANALYTICS_PRESENCE_ANY_PAGE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          presence: { front_page_only: false },
        },
      };
    case "FLYER_ANALYTICS_CATEGORY_SHARE_ANY_PAGE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          category_share: { front_page_only: false },
        },
      };
    case "FLYER_ANALYTICS_BRAND_SHARE_FRONT_PAGE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          brand_share: { front_page_only: true },
        },
      };
    case "FLYER_ANALYTICS_BRAND_SHARE_ANY_PAGE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          brand_share: { front_page_only: false },
        },
      };
    case "SET_FLYERS_TEXT_FILTER":
      return { ...state, new: { ...state.new, text_selected: action.data } };
    case "SET_FLYERS_TEXT_FILTER_ON_LOAD":
      return {
        ...defaultDataFlyersSettings,
        retailer_options: state.retailer_options,
        retailer_options_last_updated: state.retailer_options_last_updated,
        new: {
          ...defaultDataFlyersSettings.new,
          text_selected: action.data,
        },
        existing: {
          ...defaultDataFlyersSettings.existing,
          text_selected: action.data,
        },
      };
    case "SHOW_FLYER_DATE_PICKER":
      if (
        state.dates_selected &&
        state.dates_selected.start_date &&
        !state.dates_selected.end_date
      ) {
        return { ...state, showDatePicker: true, dates_selected: null };
      }
      return { ...state, showDatePicker: true };
    case "HIDE_FLYER_DATE_PICKER":
      return { ...state, showDatePicker: false };
    case "RESET_FLYERS_DATES_MODAL":
      return {
        ...state,
        new: {
          ...state.new,
          dates_selected: null,
        },
      };
    case "RESET_FLYERS_DATES":
      return {
        ...state,
        new: {
          ...state.new,
          dates_selected: null,
        },
        existing: {
          ...state.existing,
          dates_selected: null,
        },
      };
    case "FLYERS_SELECT_DATE_MODAL":
      return {
        ...state,
        new: {
          ...state.new,
          dates_selected: action.data,
        },
      };
    case "FLYERS_SELECT_DATE":
      return {
        ...state,
        new: {
          ...state.new,
          dates_selected: action.data,
        },
        existing: {
          ...state.new,
          dates_selected: action.data,
        },
      };
    case "FLYER_FILTERS_GET_CATEGORY_DATA":
      return {
        ...state,
        category_options: action.data,
        category_options_last_updated: new Date(),
      };
    case "FLYER_FILTERS_GET_RETAILER_DATA":
      return {
        ...state,
        retailer_options: action.data,
        retailer_options_last_updated: new Date(),
      };
    case "FLYER_FILTERS_APPLY_NEW_CHANGES":
      const newChanges = cloneDeep(state.new);
      return Object.assign({}, state, {
        existing: newChanges,
      });
    case "FLYERS_FILTERS_CHANGE_SORT":
      return { ...state, sort: action.data };
    case "FILTERS_CHECK_RESULTS_COUNT_FLYERS_DATA":
      const uniquePhotosCheck = [];
      const mapPhotosCheck = new Map();
      for (const itemPhotos of action.data.data) {
        if (!mapPhotosCheck.has(itemPhotos.id)) {
          mapPhotosCheck.set(itemPhotos.id, true); // set any value to Map
          uniquePhotosCheck.push(itemPhotos);
        }
      }
      return {
        ...state,
        prefetchedData: { ...action.data, data: uniquePhotosCheck },
      };
    case "FLYER_SELECT_RETAILERS":
      return {
        ...state,
        new: { ...state.new, retailers_selected: action.data },
      };
    case "FLYER_SELECT_CATEGORIES":
      return {
        ...state,
        new: { ...state.new, categories_selected: action.data },
      };
    case "FLYERS_FILTERS_RESET":
    case "LOGOUT":
      return defaultDataFlyersSettings;
    default:
      return state;
  }
};

export const mapZoom = (state = 5, action) => {
  switch (action.type) {
    case "LOCATIONS_SET_SPECIFIC_ZOOM":
      return action.data;
    case "LOCATIONS_MAP_ZOOM_IN":
      return state + 1;
    case "LOCATIONS_MAP_ZOOM_OUT":
      if (state > 3) {
        return state - 1;
      }
      return state;
    case "LOGOUT":
      return 5;
    default:
      return state;
  }
};

export const dataFlyersAnalytics = (state = {}, action) => {
  switch (action.type) {
    case "FLYERS_ANALYTICS_GET_TIMELINE_DATA":
      if (action.data && !action.data.error) {
        return {
          ...state,
          timeline: { data: action.data, last_updated: new Date() },
        };
      }
      return state;
    case "FLYERS_ANALYTICS_GET_ADJACENCIES_DATA":
      if (action.data && !action.data.error) {
        return {
          ...state,
          adjacencies: { data: action.data, last_updated: new Date() },
        };
      }
      return state;
    case "FLYERS_ANALYTICS_GET_PRESENCE_DATA":
      if (action.data && !action.data.error) {
        return {
          ...state,
          presence: { data: action.data, last_updated: new Date() },
        };
      }
      return state;
    case "FLYERS_ANALYTICS_GET_BRAND_SHARE_DATA":
      if (action.data && !action.data.error) {
        return {
          ...state,
          brand_share: { data: action.data, last_updated: new Date() },
        };
      }
      return state;
    case "FLYERS_ANALYTICS_GET_CATEGORY_SHARE_DATA":
      if (action.data && !action.data.error) {
        return {
          ...state,
          category_share: { data: action.data, last_updated: new Date() },
        };
      }
      return state;
    case "FLYERS_ANALYTICS_GET_THEMES_DATA":
      if (action.data && !action.data.error) {
        return {
          ...state,
          themes: { data: action.data, last_updated: new Date() },
        };
      }
      return state;
    case "FLYERS_ANALYTICS_GET_WEBSITES_DATA":
      if (action.data && !action.data.error) {
        return {
          ...state,
          websites: { data: action.data, last_updated: new Date() },
        };
      }
      return state;
    case "LOGOUT":
      return {};
    default:
      return state;
  }
};
export const loginData = (state = {}, action) => {
  switch (action.type) {
    case "ERROR_LOADING_COMPANY_IMG":
      return { error: true };
    case "CHECK_SSO_TLD_AND_GET_EMAIL_CODE_DATA":
      if (Array.isArray(action.data)) {
        return {
          login_tld: action.data[0],
        };
      }
      return state;
    default:
      return state;
  }
};
export const userPreferences = (state = {}, action) => {
  switch (action.type) {
    case "SHOW_FREE_PLAN_BANNER":
      return {
        ...state,
        heroBanner: true,
        lastShownFreeBanner: new Date(),
      };
    case "HIDE_FREE_PLAN_BANNER":
      return { ...state, heroBanner: false };
    case "SHOW_FULLSCREEN_SLIDESHOW":
      return { ...state, fullscreenSlideshow: true };
    case "@@router/LOCATION_CHANGE":
    case "HIDE_FULLSCREEN_SLIDESHOW":
      return { ...state, fullscreenSlideshow: false };
    case "FEED_FLYERS_SET_KEY":
      return {
        ...state,
        flyerKey: `flyer-key-${new Date().getTime()}`,
      };
    case "FEED_PLANOGRAMS_SET_KEY":
      return {
        ...state,
        planogramKey: `planogram-key-${new Date().getTime()}`,
      };
    case "FEED_PHOTOS_SET_KEY":
      return {
        ...state,
        feedKey: `feed-key-${new Date().getTime()}`,
      };
    case "STAR_BUTTON_CHECK_OFF":
    case "STAR_CHECK_ON":
      return {
        ...state,
        starKey: `star-key-${new Date().getTime()}`,
      };
    case "CLEAR_ENTERPRISE_LOGO_IN_MENU": {
      return {
        ...state,
        current_pc: { id: "" },
      };
    }
    case "SWITCH_PRIVATE_SPACE":
      return {
        ...state,
        current_pc: action.data,
      };
    case "PRIVATE_COLLECTION_DATA":
      const currentPc = state.current_pc;
      return {
        ...state,
        current_pc:
          action.data && action.data.length === 0
            ? null
            : action.data && action.data.length === 1
            ? action.data[0]
            : currentPc,
        suspended:
          action.data &&
          action.data.length > 0 &&
          action.data.filter((x) => x.suspended),
      };
    case "PRIVATE_COLLECTION_DASHBOARD_FOR_MENU_DATA":
      return {
        ...state,
        current_pc: {
          ...state.current_pc,
          dashboard: action.data,
        },
      };
    case updateFeatureGate.pending.type: {
      if (state.current_pc?.id !== action.meta.arg.body.landing_page_slug || !state.current_pc?.feature_gates) {
        return state;
      }

      return {
        ...state,
        current_pc: {
          ...state.current_pc,
          feature_gates: state.current_pc.feature_gates.map(gate =>
            gate.id === action.meta.arg.body.feature_id
              ? { ...gate, is_enabled: action.meta.arg.body.is_enabled }
              : gate
          )
        }
      };
    }

    case updateFeatureGate.rejected.type: {
      if (state.current_pc?.id !== action.meta.arg.body.landing_page_slug || !state.current_pc?.feature_gates) {
        return state;
      }

      return {
        ...state,
        current_pc: {
          ...state.current_pc,
          feature_gates: state.current_pc.feature_gates.map(gate =>
            gate.id === action.meta.arg.body.feature_id
              ? { ...gate, is_enabled: !gate.is_enabled }
              : gate
          )
        }
      };
    }
    case "LOGOUT":
      return {};
    default:
      return state;
  }
};

export const dataCampaignsSettings = (state = {}, action) => {
  switch (action.type) {
    case "CAMPAIGNS_SEARCH_FOCUS":
      return {
        ...state,
        showSearch: true,
      };
    case "CAMPAIGNS_RESET":
    case showModal.type:
    case "SHOW_SEARCH":
    case "HIDE_SEARCH":
    case "MAIN_MENU_HIDE":
    case "MAIN_MENU_SHOW_NOTIFICATIONS":
    case "MAIN_MENU_SHOW_HELP":
    case "MAIN_MENU_SHOW_PC_DASHBOARD":
    case "MAIN_MENU_SHOW_USER":
    case "CAMPAIGNS_SEARCH_BLUR":
      return {
        ...state,
        showSearch: false,
      };
    case "CAMPAIGNS_SET_QUERY":
      return {
        ...state,
        query: action.data,
      };
    case "LOGOUT":
      return {};
    default:
      return state;
  }
};

export const dataCampaigns = (state = {}, action) => {
  switch (action.type) {
    case "LOGOUT":
    case "CAMPAIGNS_RESET":
      return {};
    case "CAMPAIGNS_DATA":
      return {
        date: new Date().toString(),
        data: action.data,
      };
    default:
      return state;
  }
};

const defaultTrends = { focused: false, keywords: [{ query: "" }] };

export const trends = (state = defaultTrends, action) => {
  switch (action.type) {
    case "TRENDS_SEARCH_RESET":
      return defaultTrends;
    case "TRENDS_SEARCH_FOCUS":
      return {
        ...state,
        focused: true,
      };
    case "TRENDS_SEARCH_BLUR":
      return {
        ...state,
        focused: false,
      };
    case "TRENDS_ADD_KEYWORD": {
      return {
        ...state,
        keywords: state.keywords.concat({ query: "" }),
      };
    }
    case "TRENDS_REMOVE_KEYWORD":
      return {
        ...state,
        keywords: state.keywords.filter((x, i) => i !== action.data),
      };
    case "TRENDS_SET_KEYWORD":
      const newKeywords = cloneDeep(state.keywords);
      newKeywords[action.data.index] = { query: action.data.value };
      return {
        ...state,
        keywords: newKeywords,
      };
    case "TRENDS_GET_LINE_DATA":
      const keywordsData = cloneDeep(state.keywords);
      keywordsData[action.data.keyword_index]["data"] = action.data.data;
      return {
        ...state,
        keywords: keywordsData,
        sample_size: action.data.sample_size,
      };
    case "TRENDS_GET_KEYWORD_VISUALS_DATA":
      const withVisuals = cloneDeep(state.keywords);
      withVisuals[action.data.keyword_index]["visuals"] = action.data.data;
      return {
        ...state,
        keywords: withVisuals,
      };
    default:
      return state;
  }
};

export const showSideMenu = (state = false, action) => {
  switch (action.type) {
    case "HIDE_SIDE_MENU":
    case "SHOW_SEARCH":
    case "MAIN_MENU_SHOW_NOTIFICATIONS":
    case "MAIN_MENU_SHOW_HELP":
    case "MAIN_MENU_SHOW_PC_DASHBOARD":
    case "MAIN_MENU_SHOW_USER":
    case "LOGOUT":
      return false;
    case "SHOW_SIDE_MENU":
      return true;
    default:
      return state;
  }
};

const defaultStudioSettings = {
  stagePosition: { x: 0, y: 0 },
  panningOffset: { x: 0, y: 0 },
  showSearch: false,
  showDropArea: false,
  selectedColor: "#3CD55D",
  selectedShape: null,
  retailer: null,
  lastDownloaded: null,
  pendingShape: null,
  searchResults: null,
  showAdjustMenu: false,
  cropping: false,
  showColorMenu: false,
  stagedFacing: null,
  cursorAuto: false,
  panning: false,
  strokeIds: [],
  targets: [],
  zoom: 1,
};

export const studioSettings = (state = defaultStudioSettings, action) => {
  switch (action.type) {
    case "STUDIO_SET_PANNING_OFFSET":
      return {
        ...state,
        panningOffset: action.data,
      };
    case "STUDIO_SELECT_SHAPE_TEXT":
      return {
        ...state,
        panning: false,
        selectedShape: "text",

        showColorMenu: false,
        showAdjustMenu: false,
        targets: state.targets.map((x) => ({
          ...x,
          selected: false,
        })),
      };
    case "STUDIO_SELECT_SHAPE_RECTANGLE":
      return {
        ...state,
        panning: false,
        selectedShape: "rectangle",

        showColorMenu: false,
        showAdjustMenu: false,
        targets: state.targets.map((x) => ({
          ...x,
          selected: false,
        })),
      };
    case "STUDIO_SELECT_SHAPE_ARROW":
      return {
        ...state,
        panning: false,
        selectedShape: "arrow",

        showColorMenu: false,
        showAdjustMenu: false,
        targets: state.targets.map((x) => ({
          ...x,
          selected: false,
        })),
      };
    case "STUDIO_SELECT_SHAPE_CIRCLE":
      return {
        ...state,
        panning: false,
        selectedShape: "circle",

        showColorMenu: false,
        showAdjustMenu: false,
        targets: state.targets.map((x) => ({
          ...x,
          selected: false,
        })),
      };
    case "STUDIO_CHANGE_COLOR":
      return {
        ...state,
        selectedColor: action.data,
      };
    case "STUDIO_PERSIST_TEXT":
      const pendingShapeText = state.pendingShape;
      return {
        ...state,
        selectedShape: null,
        pendingShape: null,
        targets: state.targets.concat([
          { ...pendingShapeText, text: action.data },
        ]),
      };
    case "STUDIO_PERSIST_SHAPE_TARGET":
      const currentTargets = state.targets;
      return {
        ...state,
        selectedShape: null,
        pendingShape: null,
        targets: currentTargets.concat([action.data]),
      };
    case "STUDIO_DRAW_SHAPE_TARGET":
      return {
        ...state,
        pendingShape: {
          ...state.pendingShape,
          width: action.data.width,
          height: action.data.height,
        },
      };
    case "STUDIO_SHOW_COLOR_MENU":
      return {
        ...state,
        panning: false,
        cropping: false,
        showColorMenu: true,
        showAdjustMenu: false,
      };
    case "STUDIO_START_PANNING":
      return {
        ...state,
        showColorMenu: false,
        selectedShape: false,
        panning: true,
        targets: state.targets.map((x) => ({
          ...x,
          strokeEnabled: false,
          selected: false,
        })),
      };
    case "STUDIO_STOP_PANNING":
      return {
        ...state,
        panning: false,
      };
    case "STUDIO_SET_CURSOR_AUTO":
      return {
        ...state,
        cursorAuto: true,
      };
    case "STUDIO_RESET_CURSOR":
      return {
        ...state,
        cursorAuto: false,
      };
    case "STUDIO_SET_INITIAL_PHOTOS":
      return {
        ...state,
        zoom: action.data.zoom,
        targets: action.data.targets,
      };
    case "STUDIO_DROP_FACING":
      return {
        ...state,
        targets: state.targets.concat([
          {
            x: action.data.x,
            y: action.data.y,
            height: 100,
            width: 100 * action.data.ratio,
            showAnimation: true,
            source: "crops",
            img: action.data.img_facing,
            id: `crop-${Math.floor(Math.random() * 100)}-${Math.floor(
              Math.random() * 100
            )}-${new Date().getMilliseconds()}`,
          },
        ]),
        stagedFacing: null,
      };
    case "STUDIO_HIDE_DROP_AREA":
      return {
        ...state,
        showDropArea: false,
      };
    case "STUDIO_STAGE_FACING_FOR_DROP":
      return {
        ...state,
        showDropArea: true,
        stagedFacing: action.data,
      };
    case "STUDIO_DELETE_TARGET":
      return {
        ...state,
        strokeIds: state.strokeIds.filter((x) => x !== action.data),
        targets: state.targets.filter((x) => !action.data.includes(x.id)),
      };
    case "STUDIO_UPDATE_TARGET": {
      const targets = state.targets.map((target) => {
        if (target.id !== action.data.id) {
          return target;
        }
        return {
          ...target,
          ...action.data.attrs,
        };
      });
      return {
        ...state,
        targets,
      };
    }
    case "STUDIO_ADD_SHAPE_TARGET":
      return {
        ...state,
        pendingShape: action.data,
      };
    case "STUDIO_ADD_STROKE":
      return {
        ...state,
        showColorMenu: false,
        showAdjustMenu: false,
        targets: state.targets.map((x) => ({
          ...x,
          selected: false,
          highlighted: action.data.includes(x.id) ? true : x.highlighted,
        })),
      };
    case "STUDIO_REMOVE_STROKE":
      return {
        ...state,
        targets: state.targets.map((x) => ({
          ...x,
          highlighted: action.data.includes(x.id) ? false : x.highlighted,
        })),
      };
    case "STUDIO_DOWNLOAD_CANVAS_AS_IMAGE":
      return {
        ...state,
        lastDownloaded: `image-download-${new Date()}`,
      };
    case "STUDIO_SHOW_SEARCH":
      return {
        ...state,
        showSearch: true,
      };
    case "STUDIO_HIDE_SEARCH":
      return {
        ...state,
        searchResults: null,
        showSearch: false,
      };
    case "STUDO_SEARCH_FACINGS_DATA":
      return {
        ...state,
        searchResults: action.data,
      };
    case "STUDIO_ZOOM_IN":
      const currentZoomIn = state.zoom;
      return {
        ...state,
        zoom: currentZoomIn + 0.1,
      };
    case "STUDIO_ZOOM_OUT":
      const currentZoomOut = state.zoom;
      return {
        ...state,
        zoom: currentZoomOut - 0.1,
      };
    case "STUDIO_RESET_MENU_SELECT":
      return {
        ...state,
        cropping: false,
        panning: false,
        showColorMenu: false,
        showAdjustMenu: false,
        selectedShape: null,
      };
    case "STUDIO_SELECT_TOOL_CROP":
      return {
        ...state,
        showColorMenu: false,
        showAdjustMenu: false,
        cropping: true,
      };
    case "STUDO_SEARCH_FACINGS_DATA_RESET":
      return {
        ...state,
        searchResults: null,
      };
    case "STUDIO_RESET_SELECT":
      return {
        ...state,
        cropping: false,
        targets: state.targets.map((x) => ({
          ...x,
          strokeEnabled: false,
          selected: false,
        })),
      };
    case "STUDIO_SELECT_SHAPE":
      return {
        ...state,
        showColorMenu: false,
        showAdjustMenu: false,

        targets: state.targets.map((x) => ({
          ...x,
          selected: x.id === action.data ? true : false,
        })),
      };
    case "STUDIO_SHOW_ADJUST_MENU":
      return {
        ...state,
        showAdjustMenu: true,
        cropping: false,
        showColorMenu: false,
      };
    case "STUDIO_SET_RETAILER":
      return {
        ...state,
        retailer: action.data,
      };
    case "STUDIO_ADJUST_BRIGHTNESS_MINUS":
      return {
        ...state,
        targets: state.targets.map((x) => ({
          ...x,
          brightness:
            action.data === x.id && x.brightness > -1
              ? x.brightness - 0.1
              : x.brightness,
        })),
      };
    case "STUDIO_ADJUST_BRIGHTNESS_PLUS":
      return {
        ...state,
        targets: state.targets.map((x) => ({
          ...x,
          brightness:
            action.data === x.id && x.brightness < 1
              ? x.brightness + 0.1
              : x.brightness,
        })),
      };
    case "STUDIO_ADJUST_CONTRAST_MINUS":
      return {
        ...state,
        targets: state.targets.map((x) => ({
          ...x,
          contrast:
            action.data === x.id && x.contrast > -25
              ? x.contrast - 5
              : x.contrast,
        })),
      };
    case "STUDIO_ADJUST_CONTRAST_PLUS":
      return {
        ...state,
        targets: state.targets.map((x) => ({
          ...x,
          contrast:
            action.data === x.id && x.contrast < 90
              ? x.contrast + 5
              : x.contrast,
        })),
      };
    case "VIEWER_SHOW_DETAILS":
    case "HIDE_VIEWER":
    case "LOGOUT":
      return defaultStudioSettings;
    default:
      return state;
  }
};

export const hawkeye = (
  state = { fullscreen: false, selected: [], deletedManually: true },
  action
) => {
  switch (action.type) {
    case "HAWKEYE_APPROVE_DATA":
    case "HAWKEYE_REJECT_DATA":
      return {
        ...state,
        data: null,
      };
    case "HAWKEYE_DELETED_PHOTOS_FIRST_DATA":
      return {
        ...state,
        deleted: action.data,
      };
    case "HAWKEYE_DELETED_PHOTOS_DATA":
      const newDeleted = state.deleted.concat(action.data);
      return {
        ...state,
        deleted: newDeleted,
      };
    case "HAWKEYE_SET_DELETED_AUTOMATICALLY":
      return {
        ...state,
        deletedManually: false,
      };
    case "HAWKEYE_SET_DELETED_MANUALLY":
      return {
        ...state,
        deletedManually: true,
      };
    case "HAWKEYE_GET_AGENTS_DATA":
      return {
        ...state,
        agents: action.data,
      };
    case "HAWKEYE_GET_LEADERBOARD_DATA":
      return {
        ...state,
        leaderboard: action.data,
      };
    case "HAWKEYE_GET_QC_DATA":
      return {
        ...state,
        data: action.data.error ? null : action.data,
      };
    case "HAWKEYE_SHOW_FULLSCREEN":
      return {
        ...state,
        fullscreen: action.data,
      };
    case "HAWKEYE_CLOSE_FULLSCREEN":
      return {
        ...state,
        fullscreen: false,
      };
    default:
      return state;
  }
};
