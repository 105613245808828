export function isObject(value) {
    return !!value && typeof value === "object" && !Array.isArray(value);
}
export function isDefined(value) {
    return value !== null && value !== undefined;
}
export function isTruthy(value) {
    return !!value;
}
/**
 * Wraps a read-only (const) array to provide a type-safe membership check via an `includes` method.
 *
 * This helper alleviates the strictness of the native `Array.prototype.includes` method.
 * By returning an `includes` method that acts as a type guard, it allows for checking membership
 * against a subset of literal types without running into type errors when the value is part of a broader union.
 *
 */
export function memberOf(arr) {
    return {
        includes: function (value) {
            return arr.includes(value);
        }
    };
}
/**
 * Wraps an object to provide a type-safe membership check against its keys via an `includes` method.
 *
 * This acts as a type guard to alleviate the lack of type narrowing that the standard
 * `value in object` check provides when the value is part of a broader union.
 */
export function keyOf(obj) {
    return {
        contains: function (value) {
            return value in obj;
        }
    };
}
