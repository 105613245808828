import { createApiThunk } from "@redux/api/createApiThunk";
export var toggleAdvancedFilters = createApiThunk({
    route: "user/advanced_filters",
    component: "TOGGLE_ADVANCED_FILTERS",
    method: "POST"
});
export var logFeatureFound = createApiThunk({
    method: "POST",
    component: "FEATURE_FOUND",
    route: "user/feature_found",
});
