var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { FILTER_RULES_FULL_WIDTH_BREAKPOINT } from "components/feed/filter-rules/FilterRules.config";
/**
 * This H3 is defined here because it is used in `presetOsaOptions` in `FilterRules.config.tsx`.
 * and it causes a circular import when defined in `Condition.tsx`
 * @see presetOsaOptions
 */
export var H3 = styled.h3(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  width: auto;\n  font-weight: bold;\n  margin: 0 0.5em 0 0;\n  padding: 0 0.35em;\n  display: flex;\n  text-transform: uppercase;\n  text-align: left;\n  align-items: center;\n  justify-content: center;\n\n  @media (max-width: ", "px) {\n    padding: 0.5em;\n  };\n"], ["\n  color: ", ";\n  width: auto;\n  font-weight: bold;\n  margin: 0 0.5em 0 0;\n  padding: 0 0.35em;\n  display: flex;\n  text-transform: uppercase;\n  text-align: left;\n  align-items: center;\n  justify-content: center;\n\n  @media (max-width: ", "px) {\n    padding: 0.5em;\n  };\n"])), function (_a) {
    var theme = _a.theme;
    return theme.grey2;
}, FILTER_RULES_FULL_WIDTH_BREAKPOINT);
var templateObject_1;
